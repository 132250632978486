<template>
  <div>
    <section class="section__primary">
      <h1 class="section__title">Informations professionnelles</h1>
      <div class="section__body relative">
        <div
          v-if="
            account_form?.stagiaireEC || account_form?.stagiaireMemorialiste
          "
          class="mb-4 text-base/tight text-jinius-red">
          Pour tout changement relatif à vos informations (changement de
          cabinet, coordonnées personnelles), veuillez contacter impérativement
          votre service de stage pour validation et prise en compte.
        </div>

        <div class="mb-3 lg:absolute lg:right-0 lg:top-0 lg:mb-0">
          <div
            class="club-card"
            v-if="clubs && clubs.length">
            <div class="card__container">
              <div class="card__container--anchor"></div>
              <div class="container__content">
                <div class="content__title">
                  <div class="title__icon">
                    <font-awesome-icon
                      icon="users"
                      class="icon"
                      size="sm" />
                  </div>
                  <span class="title__text">Club de contacts</span>
                </div>
                <div class="content__clubs">
                  <div
                    class="club"
                    v-for="club in clubs"
                    v-tooltip="
                      club.nom.length > 33
                        ? {
                            content: club.nom,
                            placement: 'left'
                          }
                        : {}
                    "
                    v-bind:key="club.id">
                    {{ club.nom }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form__item mt-3">
          <radio-select-input
            id="radioCRCC"
            title="Êtes-vous Commissaire aux Comptes (CAC) ?"
            input_name="estInscritCRCC"
            yes_title="Inscrit(e) à la CRCC de :"
            v-bind:list="crcc"
            v-bind:value="account_form.estInscritCRCC"
            v-bind:select_value="
              account_form.estInscritCRCC && account_form.crcc
            "
            v-on:input="handleCrcc"
            v-on:select="set_attribute($event.id, 'crcc')"
            v-bind:required="true"
            v-bind:input_class="
              v$.accountForm.estInscritCRCC.$error ? 'input-error' : ''
            "
            v-bind:select_class="
              v$.accountForm.crcc.$error ? 'select-error' : ''
            "
            v-bind:disabled="account_form.stagiaireCAC" />
          <div
            v-if="account_form.stagiaireCAC"
            class="mb-5 mt-6 flex w-full flex-wrap space-y-2 sm:flex-nowrap sm:space-x-4 sm:space-y-0">
            <radio-button
              id="radio-stagiaire-cac"
              label="Stagiaire CAC"
              disabled
              v-bind:checked="account_form.stagiaireCAC"
              name="stagiaireEC"
              value="siret"
              class="radio-pills"
              v-bind:class="{
                'radio-pills--active': account_form.stagiaireCAC
              }" />
          </div>
        </div>

        <div class="form__item">
          <radio-select-input
            id="radioCROEC"
            title="Êtes-vous Expert comptables (EC) ?"
            input_name="estInscritCROEC"
            yes_title="Inscrit(e) à l'Ordre de :"
            v-bind:list="croec"
            v-bind:value="account_form.estInscritCROEC"
            v-bind:select_value="
              account_form.estInscritCROEC && account_form.croec
            "
            v-on:input="handleCroec"
            v-on:select="set_attribute($event.id, 'croec')"
            v-bind:required="true"
            v-bind:input_class="
              v$.accountForm.estInscritCROEC.$error ? 'input-error' : ''
            "
            v-bind:select_class="
              v$.accountForm.croec.$error ? 'select-error' : ''
            "
            v-bind:disabled="account_form.numeroSupra !== undefined" />

          <transition name="slide-up">
            <div
              v-if="account_form.estInscritCROEC && account_form.numeroSupra"
              class="form__item mx-6 mt-3">
              <input-box
                disabled
                v-if="account_form.estInscritCROEC"
                class_name="no-box"
                label="N° d'identification à l'Ordre"
                placeholder="N° Supra"
                name="numeroSupra"
                mask="00 00000000 00"
                v-bind:value="
                  account_form.estInscritCROEC && account_form.numeroSupra
                "
                v-on:input="set_attribute($event, 'numeroSupra')"
                format="numeric" />
              <div
                class="mb-5 mt-6 flex w-full flex-wrap space-y-2 sm:flex-nowrap sm:space-x-4 sm:space-y-0">
                <radio-button
                  disabled
                  id="radio-stagiaire-ec"
                  label="Stagiaire EC"
                  v-bind:checked="account_form.stagiaireEC"
                  name="stagiaireEC"
                  value="siret"
                  class="radio-pills"
                  v-bind:class="{
                    'radio-pills--active': account_form.stagiaireEC
                  }" />
                <radio-button
                  id="radio-stagiaire-memorialite"
                  label="Stagiaire Mémorialiste"
                  disabled
                  v-bind:checked="account_form.stagiaireMemorialiste"
                  name="stagiaireMemorialiste"
                  value="siret"
                  class="radio-pills"
                  v-bind:class="{
                    'radio-pills--active': account_form.stagiaireMemorialiste
                  }" />
              </div>
            </div>
          </transition>
        </div>

        <div class="form__item">
          <div class="mb-2 flex w-full flex-wrap">
            <radio-select-input
              id="radioANECS"
              title="Êtes-vous membre ANECS & CJEC ?"
              input_name="rattachementAnecsCjec"
              v-bind:value="account_form.rattachementAnecsCjec"
              v-on:input="
                (value) => set_attribute(value, 'rattachementAnecsCjec')
              "
              v-bind:required="true" />
          </div>
          <transition name="slide-up">
            <div
              v-if="account_form.rattachementAnecsCjec"
              class="form__item mx-6 mt-3">
              <input-box
                class_name="no-box"
                label="Numéro d'adhérent"
                placeholder="Numéro d'adhérent"
                name="numAdherentAnecsCjec"
                v-bind:maxlength="
                  v$?.accountForm?.numAdherentAnecsCjec?.$params &&
                  'maxLength' in v$.accountForm.numAdherentAnecsCjec.$params
                    ? v$.accountForm.numAdherentAnecsCjec.$params.maxLength.max
                    : 14
                "
                v-bind:value="
                  account_form.rattachementAnecsCjec &&
                  account_form.numAdherentAnecsCjec
                "
                v-on:input="set_attribute($event, 'numAdherentAnecsCjec')"
                v-bind:class="{
                  'input-error': v$.accountForm.numAdherentAnecsCjec.$error
                }"
                v-bind:required="true" />

              <div class="mt-3 text-jinius-red">
                <datepicker-box
                  v-bind:required="true"
                  class_name="no-box"
                  left_icon="calendar-alt"
                  show_clear_button
                  format="dd/MM/yyyy"
                  label="Date de fin d'adhésion"
                  placeholder="Saisie manuelle (jj/mm/aaaa) ou Sélection dans le calendrier"
                  v-bind:value="account_form.dateFinAdhesionAnecsCjec"
                  v-on:input="set_attribute($event, 'dateFinAdhesionAnecsCjec')"
                  v-bind:min_date="new Date(2022, 1, 1)"
                  v-bind:class="{
                    'datepicker-error':
                      v$.accountForm.dateFinAdhesionAnecsCjec.$error
                  }" />
              </div>
              <div
                class="mt-3 font-bold italic text-jinius-red"
                v-if="account_form.isDateDeFinAdhesionDepassee">
                <font-awesome-icon
                  class="mr-2"
                  icon="exclamation-triangle"
                  size="1x" />
                La date de fin d'adhésion est dépassée
              </div>
            </div>
          </transition>
        </div>

        <div class="form__item mt-3">
          <select-box
            v-bind:value="
              situations.find(
                (situation) => account_form.situation === situation.id
              )
            "
            v-on:input="(value) => set_attribute(value.id, 'situation')"
            v-bind:categories="situations"
            label="Situation"
            placeholder="Situation"
            no_capitalize_label
            not_required_label
            tracking="id"
            class_name="no-box" />
        </div>

        <div class="form__item">
          <input-box
            class_name="no-box cursor-pointer"
            left_icon="building"
            label="Société / Employeur"
            placeholder="Société / Employeur"
            name="societe"
            v-bind:maxlength="100"
            v-bind:value="account_form.societeLabel"
            v-bind:required="
              v$.accountForm.societeLabel &&
              Object.prototype.hasOwnProperty.call(
                v$.accountForm.societeLabel,
                'required'
              )
            "
            v-bind:class="{
              'input-error':
                v$.accountForm.societeLabel &&
                v$.accountForm.societeLabel.$error
            }"
            v-bind:onclick="openClientModal" />
        </div>

        <div class="form__item mt-3">
          <input-box
            class_name="no-box"
            label="Intitulé du poste"
            placeholder="Intitulé du poste"
            name="posteIntitule"
            not_required_label
            v-bind:maxlength="50"
            v-bind:value="account_form.posteIntitule"
            v-on:input="set_attribute($event, 'posteIntitule')" />
        </div>
        <div class="form__item">
          <select-box
            v-bind:value="
              niveauFormations.find(
                (niveauFormation) =>
                  account_form.niveauFormation === niveauFormation.id
              )
            "
            v-on:input="(value) => set_attribute(value.id, 'niveauFormation')"
            v-bind:categories="niveauFormations"
            label="Niveau de diplôme"
            placeholder="Niveau de diplôme"
            not_required_label
            no_capitalize_label
            tracking="id"
            class_name="no-box" />
        </div>
      </div>
    </section>
    <client-modal
      title="Sélection de la société / employeur"
      v-bind:onclose="closeClientModal"
      v-bind:on_client_form_submit="onClientFormSubmit"
      v-bind:modal_show="client_modal" />
  </div>
</template>
<script>
  import { niveauFormations, situations } from '@/assets/data/data_form';
  import datepickerBox from '@/components/utils/datepicker-box';
  import inputBox from '@/components/utils/input-box.vue';
  import radioButton from '@/components/utils/radio-button';
  import radioSelectInput from '@/components/utils/radio-select-input';
  import selectBox from '@/components/utils/select-box';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import ClientModal from '@/views/_espace/_account/_informations/_modal/client-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');

  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      radioButton,
      ClientModal,
      selectBox,
      inputBox,
      radioSelectInput,
      datepickerBox
    },
    props: ['set_attribute', 'account_form', 'v$'],
    data: () => ({ client_modal: false }),
    computed: {
      ...mapStateObserver(['showSearchModal']),
      ...mapStateAccount(['crcc', 'croec', 'societes', 'clubs']),
      ...mapStateClient(['clientForm']),
      situations: () => situations,
      niveauFormations: () => niveauFormations
    },
    methods: {
      ...mapActionsAccount([
        ACTION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE,
        ACTION_TYPES.FILL_ACCOUNT_FORM_IF_EMPTY
      ]),
      openClientModal(e) {
        e.preventDefault();
        this.client_modal = true;
      },
      closeClientModal() {
        if (!this.showSearchModal) {
          this.client_modal = false;
        }
      },
      handleCroec(value) {
        this.set_attribute(value, 'estInscritCROEC');
        if (!value) {
          this.set_attribute(null, 'croec');
        }
      },
      handleCrcc(value) {
        this.set_attribute(value, 'estInscritCRCC');
        if (!value) {
          this.set_attribute(null, 'crcc');
        }
      },
      onClientFormSubmit(data) {
        this.setAccountForm(data.id, 'societe');
        this.setAccountForm(data.denomination, 'societeLabel');
        this[ACTION_TYPES.FILL_ACCOUNT_FORM_IF_EMPTY](this.clientForm);
      },
      setAccountForm(value, name) {
        this[ACTION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE]({
          name,
          value
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .club-card {
    @apply overflow-hidden rounded-lg bg-white shadow;
    width: 100%;
    max-width: 332px;
    @media (min-width: 1024px) {
      width: 332px;
    }

    .card__container {
      @apply relative;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8 border-jinius-green;
      }

      .container__content {
        @apply px-4 py-5 tracking-tight shadow;

        .content__title {
          @apply mb-4 flex items-center justify-center text-xl font-bold leading-tight;

          .title__icon {
            @apply flex h-9 w-9 items-center justify-center rounded-full bg-jinius-green;

            .icon {
              @apply text-white;
            }
          }
          .title__text {
            @apply ml-2;
          }
        }
        .content__clubs {
          @apply text-center;
          .club {
            @apply mt-2 overflow-hidden text-ellipsis rounded bg-jinius-green px-3 py-1.5 text-center font-bold uppercase text-white shadow-inner;
          }
        }
      }
    }
  }
</style>
