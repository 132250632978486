<template>
  <template v-if="!loading">
    <refonte-card>
      <refonte-filters-tabs>
        <template v-slot:default>
          <refonte-filters-tabs-item
            label="Inscriptions"
            v-bind:selected="
              filters.statutIds &&
              filters.statutIds.join(', ') ===
                INSCRIPTIONS_STATUTS_IDS.join(', ')
            "
            v-bind:on_click="
              () => {
                inscriptionFilterStore.setFilter(
                  INSCRIPTIONS_FILTERS_CONFIGURATION.statutIds.name,
                  INSCRIPTIONS_STATUTS_IDS
                );
              }
            " />
          <refonte-filters-tabs-item
            label="Inscriptions annulées/transférées"
            v-bind:selected="
              filters.statutIds &&
              filters.statutIds.join(', ') ===
                INSCRIPTIONS_ANNULEES_STATUTS_IDS.join(', ')
            "
            v-bind:on_click="
              () => {
                inscriptionFilterStore.setFilter(
                  INSCRIPTIONS_FILTERS_CONFIGURATION.statutIds.name,
                  INSCRIPTIONS_ANNULEES_STATUTS_IDS
                );
              }
            " />
        </template>
      </refonte-filters-tabs>
      <inscriptions-filters />
    </refonte-card>
    <refonte-card
      v-if="countLoaded"
      class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-if="
            inscriptionCount?.nbInscriptions ||
            inscriptionCount?.nbInscriptions == 0
          "
          v-bind:number="inscriptionCount?.nbInscriptions"
          v-bind:label="`${inscriptionCount?.nbInscriptions > 1 ? 'Inscriptions' : 'Inscription'}${
            filters.statutIds &&
            filters.statutIds.join(', ') ===
              INSCRIPTIONS_ANNULEES_STATUTS_IDS.join(', ')
              ? inscriptions.length > 1
                ? ' annulées/transférées'
                : ' annulée/transférée'
              : ''
          }`" />
        <IconSeparatorVertical v-if="showOptionalFilters" />
        <refonte-table-number
          v-if="
            showOptionalFilters &&
            (inscriptionCount?.nbConventionsASigner ||
              inscriptionCount?.nbConventionsASigner == 0)
          "
          v-bind:number="inscriptionCount?.nbConventionsASigner"
          v-bind:label="`${
            inscriptionCount?.nbConventionsASigner > 1
              ? 'Conventions'
              : 'Convention'
          } à signer`"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'CONVENTIONS_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical v-if="showOptionalFilters" />
        <refonte-table-number
          v-if="
            showOptionalFilters &&
            (inscriptionCount?.nbPifsAsigner ||
              inscriptionCount?.nbPifsAsigner == 0)
          "
          v-bind:number="inscriptionCount?.nbPifsAsigner"
          label="PIF à signer"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'PIF_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical v-if="showOptionalFilters" />
        <refonte-table-number
          v-if="
            showOptionalFilters &&
            (inscriptionCount?.nbTestPositionnementNonRealise ||
              inscriptionCount?.nbTestPositionnementNonRealise == 0)
          "
          v-bind:number="inscriptionCount?.nbTestPositionnementNonRealise"
          v-bind:label="`${
            inscriptionCount?.nbTestPositionnementNonRealise > 1
              ? 'Tests'
              : 'Test'
          } de positionnement non ${
            inscriptionCount?.nbTestPositionnementNonRealise > 1
              ? 'réalisés'
              : 'réalisé'
          }`"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'T_POS_NON_REALISE'
              );
            }
          " />
        <div class="flex flex-grow items-center justify-end">
          <refonte-button
            v-bind:icon_left="IconExport"
            v-on:click="exportCsv">
            Exporter
          </refonte-button>
        </div>
      </div>
      <refonte-separator class="px-[12px]" />
      <inscriptions-content
        v-if="!dataLoading"
        v-bind:fetch="fetchData" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InscriptionsContent from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-content.vue';
  import {
    INSCRIPTIONS_ANNULEES_STATUTS_IDS,
    INSCRIPTIONS_EN_ATTENTE_STATUTS_IDS,
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    INSCRIPTIONS_STATUTS_IDS,
    useInscriptionFilterStore,
    useInscriptionStore
  } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import InscriptionsFilters from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconExport from '@/assets/img/refonte/icons/action/icon-export.svg?component';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';
  import filters_utils from '@/mixin/utils/filters_utils';
  import {
    Filters,
    InscriptionCount
  } from '@/types/gestion/inscriptions-filters-types';
  import { API } from '@/http-common';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const inscriptionFilterStore = useInscriptionFilterStore();

  const { filters } = storeToRefs(inscriptionFilterStore);

  const dataLoading = ref<boolean>(false);
  const inscriptionStore = useInscriptionStore();
  const { inscriptions } = storeToRefs(inscriptionStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await inscriptionStore.fetchInscriptions(route.query);
    dataLoading.value = false;
  };

  const exportCsv = async () => {
    await inscriptionStore.exportCsv(route.query);
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );
  const initFiltersCompleted = ref<boolean>(false);
  const initFilters = async () => {
    inscriptionFilterStore.initFiltersFromQuery(route.query);
    initFiltersCompleted.value = true;
  };

  onMounted(async () => {
    await initFilters();
    if (!activeClientIds.value) {
      await fetchDefaultData();
    }
  });

  const router = useRouter();

  watch(filters, async (newValue, oldValue) => {
    let query = getQuery(filters.value);

    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    if (
      JSON.stringify(newValue.statutIds) !== JSON.stringify(oldValue.statutIds)
    ) {
      await fetchCount();
    }
    await router.push({ query });
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);
  const showOptionalFilters = computed(() => {
    return (
      filters.value.statutIds &&
      (filters.value.statutIds.join(', ') ===
        INSCRIPTIONS_STATUTS_IDS.join(', ') ||
        filters.value.statutIds.join(', ') ===
          INSCRIPTIONS_EN_ATTENTE_STATUTS_IDS.join(', '))
    );
  });

  const countLoaded = ref<boolean>(false);
  const inscriptionCount = ref<InscriptionCount>();

  const fetchCount = async () => {
    countLoaded.value = false;

    const query = [];
    if (activeClientIds.value.length) {
      query.push(`clientIds=${activeClientIds.value}`);
    }

    if (filters.value.statutIds) {
      query.push(`statutIds=${filters.value.statutIds.join(',')}`);
    }

    const response = await API.get(
      `client/v2/inscriptions/count${query.length ? `?${query.join('&')}` : ''}`
    );

    if (response && response.data) {
      inscriptionCount.value = response.data;
    }
    countLoaded.value = true;
  };

  watch(activeClientIds, async () => {
    await fetchCount();
  });

  onMounted(async () => {
    await fetchCount();
  });
</script>

<style lang="scss" scoped></style>
