<template>
  <template v-if="!loading">
    <refonte-card>
      <interlocuteurs-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="interlocuteurs.length"
          v-bind:label="
            interlocuteurs.length > 1 ? 'Interlocuteurs' : 'Interlocuteur'
          " />
        <IconSeparatorVertical />
        <refonte-button
          v-bind:disabled="activeEntrepriseIds.length !== 1"
          v-bind:on_click="openInterlocuteurModalOpen"
          v-bind:title="
            activeEntrepriseIds.length !== 1
              ? 'Veuillez sélectionner 1 établissement pour pouvoir utiliser cette fonctionnalité.'
              : undefined
          ">
          Ajouter interlocuteur
        </refonte-button>
      </div>
      <refonte-separator class="px-[12px]" />
      <interlocuteurs-content
        v-if="!dataLoading"
        v-bind:update_interlocuteur="updateInterlocuteur"
        v-bind:delete_interlocuteur="deleteInterlocuteur" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
  <refonte-modal-interlocuteur
    v-if="!modalLoading"
    v-bind:show="isInterlocuteurModalOpen"
    v-bind:on_close="closeInterlocuteurModalOpen"
    v-bind:on_submit="addOrUpdateInterlocuteur"
    v-bind:active_interlocutor="activeInterlocuteur"
    v-bind:available_participants="participants" />
  <refonte-modal-confirmation-action
    v-bind:show="isConfirmationActionDoneModalOpen"
    v-bind:on_close="closeConfirmationActionDoneModal"
    v-bind:title="confirmationActionDoneModalTitle"
    v-bind:content="confirmationActionDoneModalDescription" />
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InterlocuteursContent from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-content.vue';
  import {
    useInterlocuteurFilterStore,
    useInterlocuteurStore
  } from '@/stores/interlocuteurs';
  import { storeToRefs } from 'pinia';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import InterlocuteursFilters from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteModalInterlocuteur from '@/components/refonte/modals/refonte-modal-interlocuteur.vue';
  import {
    AnnuaireInterlocuteur,
    Interlocuteur,
    InterlocuteurForm
  } from '@/types/gestion/interlocuteurs-types';
  import { API } from '@/http-common';
  import RefonteModalConfirmationAction from '@/components/refonte/modals/refonte-modal-confirmation-action.vue';
  import filters_utils from '@/mixin/utils/filters_utils';
  import { Filters } from '@/types/gestion/inscriptions-filters-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeEntrepriseIds, activeAdminClientIds } =
    storeToRefs(entrepriseStore);
  const interlocuteurFilterStore = useInterlocuteurFilterStore();

  const { filters } = storeToRefs(interlocuteurFilterStore);

  const dataLoading = ref<boolean>(false);
  const interlocuteurStore = useInterlocuteurStore();
  const { interlocuteurs } = storeToRefs(interlocuteurStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await interlocuteurStore.fetchInterlocuteurs(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      if (initFiltersCompleted.value) {
        await fetchData();
      }
    }
  );

  const initFiltersCompleted = ref<boolean>(false);
  const initFilters = async () => {
    interlocuteurFilterStore.initFiltersFromQuery(route.query);
    initFiltersCompleted.value = true;
  };

  onMounted(async () => {
    await initFilters();
    if (!activeAdminClientIds.value) {
      await fetchDefaultData();
    }
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = getQuery(filters.value);
    if (activeAdminClientIds.value && activeAdminClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeAdminClientIds.value
      };
    }
    await router.push({ query });
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, []);

  const activeInterlocuteur = ref<Interlocuteur>();
  const isInterlocuteurModalOpen = ref<boolean>(false);

  function closeInterlocuteurModalOpen() {
    isInterlocuteurModalOpen.value = false;
    activeInterlocuteur.value = undefined;
  }
  const modalLoading = ref<boolean>(false);
  const participants = ref<AnnuaireInterlocuteur[]>();
  const openInterlocuteurModalOpen = async () => {
    await fetchParticipants();
    activeInterlocuteur.value = undefined;
    isInterlocuteurModalOpen.value = true;
  };
  const fetchParticipants = async () => {
    modalLoading.value = true;
    const response = await API.get(
      `client/v2/${activeEntrepriseIds.value[0]}/interlocuteurs/annuaires`,
      { headers: { 'SKIP-REDIRECTION': true } }
    );
    if (response && response.data) {
      participants.value = response.data.map((p) => ({
        ...p,
        nomPrenom: `${p.nom} ${p.prenom}`
      }));
    }
    modalLoading.value = false;
  };
  function updateInterlocuteur(value: Interlocuteur) {
    isInterlocuteurModalOpen.value = true;
    activeInterlocuteur.value = value;
  }

  const isConfirmationActionDoneModalOpen = ref<boolean>(false);
  const confirmationActionDoneModalTitle = ref<string>();
  const confirmationActionDoneModalDescription = ref<string>();

  function closeConfirmationActionDoneModal() {
    isConfirmationActionDoneModalOpen.value = false;
    confirmationActionDoneModalTitle.value = undefined;
    confirmationActionDoneModalDescription.value = undefined;
  }
  const deleteInterlocuteur = async (value: Interlocuteur) => {
    const { clientId, id, email } = value;
    const response = await API.delete(
      `client/v2/${clientId}/interlocuteurs/${id}`,
      {
        headers: {
          'SKIP-REDIRECTION': true
        }
      }
    );
    if (response.status === 200) {
      isConfirmationActionDoneModalOpen.value = true;
      confirmationActionDoneModalTitle.value = 'Interlocuteur supprimé';
      confirmationActionDoneModalDescription.value = `${email} a bien été supprimé de votre liste d interlocuteurs`;
    }
    await fetchData();
  };

  async function addOrUpdateInterlocuteur(interlocuteur: InterlocuteurForm) {
    if (activeInterlocuteur.value) {
      const response = await API.put(
        `client/v2/${activeInterlocuteur.value?.clientId}/interlocuteurs/${activeInterlocuteur.value?.id}`,
        interlocuteur
      );
      if (response.status === 200) {
        isConfirmationActionDoneModalOpen.value = true;
        confirmationActionDoneModalTitle.value = 'Droits modifiés';
        confirmationActionDoneModalDescription.value =
          'Les modifications ont été enregistrées avec succès.';
      }
    } else {
      const response = await API.post(
        `client/v2/${activeEntrepriseIds.value[0]}/interlocuteurs`,
        interlocuteur
      );
      if (response.status === 200 || response.status === 201) {
        isConfirmationActionDoneModalOpen.value = true;
        confirmationActionDoneModalTitle.value = 'Interlocuteur ajouté';
        confirmationActionDoneModalDescription.value = `${interlocuteur.email} a bien été ajouté à votre liste d interlocuteur(s).`;
      }
    }
    closeInterlocuteurModalOpen();
    await fetchData();
  }
</script>

<style lang="scss" scoped></style>
