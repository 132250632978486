<template>
  <div class="p-[8px]">
    <template v-if="sessions.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="sessions-1"
        table_index="sessions-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="sessions">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Session } from '@/types/gestion/sessions-types.js';
  import { useSessionStore } from '@/stores/sessions';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { h } from 'vue';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import { useRouter } from 'vue-router';
  import dates_helpers from '@/mixin/dates_helpers';
  import SessionsTableItemDocuments from '@/views/_espace/_refonte/gestion/_sessions/sessions-table-item-documents.vue';

  const router = useRouter();
  const goToDetails = (row: Session) => {
    router.push(`/gestion/sessions-details?id=${row.id}`);
  };
  const goToCursusDetails = (row: Session) => {
    return `/gestion/inscriptions-cursus?clientId=${row.clientId}`;
  };

  const columns = [
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      content_sub_text: (row: Session) =>
        `${row.modeLabel}${row.lieu ? ` - ${row.lieu}` : ''}`,
      content_sub_link_text: (row: Session) =>
        `${row.titreAlt ? row.titreAlt : ''}`,
      content_sub_link_class: '!text-refonte-cursus',
      content_sub_link_prefix: 'Cursus : ',
      content_sub_link_path: goToCursusDetails,
      min_width: 240,
      width: 360
    },
    {
      content_index: 'datesFormations',
      label: 'Dates de formations',
      content_sub_text: (row: Session) =>
        row.dureeMinutes
          ? `Durée : ${dates_helpers.methods.formatMinutesToHours(row.dureeMinutes)}`
          : undefined,
      min_width: 145,
      width: 190
    },
    {
      content_index: 'participants',
      label: 'Participants',
      content_icon: () => IconParticipants,
      content_icon_black: true,
      width: 100
    },
    {
      content_index: 'statutLabel',
      label: 'Statut',
      width: 85,
      content_custom: (row: Session) =>
        h('span', {
          innerHTML: row.statutLabel,
          class:
            row.statutLabel === 'Annulé' || row.statutLabel === 'Transféré'
              ? 'text-jinius-red'
              : ''
        })
    },
    {
      content_index: 'organisateur',
      label: 'Organisme de formation',
      min_width: 150,
      width: 170
    },
    {
      content_index: 'id',
      label: 'Documents',
      min_width: 150,
      width: 200,
      content_custom: (row: Session) =>
        h(SessionsTableItemDocuments, {
          row
        }),
      tooltip: ' ',
      class: (row: Session) => {
        if (row.conventionStatut || row.pifStatut || row.certificatLabel) {
          return '!bg-refonte-pale-violet-2 !flex-grow-0';
        }
        return '!flex-grow-0';
      }
    }
  ];

  const sessionStore = useSessionStore();

  const { sessions } = storeToRefs(sessionStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
