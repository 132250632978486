<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.repondu)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:number="filters.repondu !== undefined ? 1 : undefined">
        Répondu
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="repondu in reponduList"
            v-bind:on_click="
              () =>
                updateFilter(
                  SATISFACTION_FILTERS_CONFIGURATION.repondu.name,
                  repondu
                )
            "
            v-bind:key="repondu ? 'Oui' : 'Non'">
            {{ repondu ? 'Oui' : 'Non' }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import { computed, onMounted, watch } from 'vue';
  import {
    SATISFACTION_FILTERS_CONFIGURATION,
    useSatisfactionFilterStore
  } from '@/stores/satisfaction';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import filters_utils from '@/mixin/utils/filters_utils';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';

  const satisfactionFilterStore = useSatisfactionFilterStore();
  const { filters } = storeToRefs(satisfactionFilterStore);

  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[] | number[]
  ) => {
    satisfactionFilterStore.setFilter(name, value);
  };

  const resetFilters = () => {
    satisfactionFilterStore.resetFilters();
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: SATISFACTION_FILTERS_CONFIGURATION.repondu.name,
      value: filters_utils.methods.hasValue(filters.value.repondu)
        ? filters_utils.methods.formatFilter(
            'Répondu',
            filters.value.repondu ? 'Oui' : 'Non'
          )
        : undefined
    }
  ]);

  const reponduList = computed(() => [true, false]);

  const entrepriseStore = useEntrepriseStore();
  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);

  watch(activeEntrepriseIds, () => {
    resetFilters();
  });

  onMounted(async () => {
    resetFilters();
  });
</script>

<style lang="scss" scoped></style>
