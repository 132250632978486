<template>
  <div class="p-[8px]">
    <template v-if="annuaire.length">
      <refonte-table
        key="annuaire-1"
        table_index="annuaire-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="annuaire" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <refonte-modal-confirmation-suppression
    v-bind:show="isConfirmationSuppressionModalOpen"
    v-bind:on_close="() => closeConfirmationSuppressionModal()"
    v-bind:on_submit="supprimerContact"
    title="Supprimer ce contact"
    v-bind:description="confirmationSuppressionModalDescription" />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useAnnuaireStore } from '@/stores/annuaire';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Annuaire } from '@/types/gestion/annuaire-types';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';
  import IconFailed20Red from '@/assets/img/refonte/icons/information/icon-failed-20-red.svg?component';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteModalConfirmationSuppression from '@/components/refonte/modals/refonte-modal-confirmation-suppression.vue';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      delete_contact?: Function;
    }>(),
    {}
  );

  const columns = [
    {
      content_index: 'contactNomPrenom',
      label: 'Nom',
      width: 180
    },
    {
      content_index: 'compteJiniusLabel',
      label: 'Compte Jinius',
      width: 110,
      content_icon: (row: Annuaire) =>
        row.compteJiniusLabel === 'Oui' ? IconValidate20Green : IconFailed20Red
    },
    {
      content_index: 'email',
      label: 'Email',
      width: 176,
      class: () => 'table-item-with-content-word-break'
    },
    {
      content_index: 'interlocuteurLabel',
      label: 'Interlocuteur',
      width: 120,
      content_icon: (row: Annuaire) =>
        row.interlocuteurLabel === 'Oui' ? IconValidate20Green : IconFailed20Red
    },
    {
      content_index: 'profilLabel',
      label: 'Profil',
      width: 200
    },
    {
      content_index: 'supprimable',
      content_button_icon: (row: Annuaire) => IconClose,
      content_button_on_click: (row: Annuaire) => {
        confirmationSuppressionModalDescription.value = `Vous êtes sur le point de supprimer le rattachement de ${row.contactNomPrenom} rattaché à ${row.clientDenomination}. Voulez-vous continuer ?`;
        openConfirmationSuppressionModal(row);
      },
      content_button_variant: (row: Annuaire) => 'danger',
      content_button_title: (row: Annuaire) =>
        row.supprimable ? 'Supprimer' : 'Suppression impossible',
      content_button_disabled: (row: Annuaire) => !row.supprimable,
      width: 40
    }
  ];

  const annuaireStore = useAnnuaireStore();

  const { annuaire } = storeToRefs(annuaireStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);
  const selectedAnnuaire = ref<Annuaire>();

  const isConfirmationSuppressionModalOpen = ref<boolean>(false);
  const confirmationSuppressionModalDescription = ref<string>();

  function openConfirmationSuppressionModal(row: Annuaire) {
    isConfirmationSuppressionModalOpen.value = true;
    selectedAnnuaire.value = row;
  }
  function closeConfirmationSuppressionModal() {
    isConfirmationSuppressionModalOpen.value = false;
    confirmationSuppressionModalDescription.value = undefined;
  }
  const supprimerContact = async () => {
    selectedAnnuaire.value &&
      props.delete_contact &&
      props.delete_contact(selectedAnnuaire.value);
  };
</script>

<style lang="scss" scoped></style>
