<template>
  <refonte-dropdown
    v-if="displayedEntreprises && displayedEntreprises.length > 1"
    class="header-scope-dropdown"
    v-bind:on_open="() => setSearchValue('')"
    v-bind:on_search="(value: string) => setSearchValue(value)">
    <div
      class="refonte-header-scope header-scope-with-entities"
      v-bind:class="activeRubrique.admin ? 'refonte-header-scope-admin' : ''">
      <div class="header-scope-icon">
        <IconEstablishments />
      </div>
      <refonte-item-all-entities
        v-if="activeEntrepriseIds.length === 0"
        v-bind:admin="activeRubrique.admin" />
      <refonte-item-entity
        v-else-if="
          activeEntrepriseIds.length === 1 && selectedEntities.length === 1
        "
        v-bind:entity="selectedEntities[0]"
        v-bind:light="true"
        v-bind:admin="activeRubrique.admin" />
      <refonte-item-all-entities
        v-else
        v-bind:title="`${activeEntrepriseIds.length} établissements sélectionnés`"
        v-bind:admin="activeRubrique.admin"
        class="no-ellipsis" />
      <div class="header-scope-chevron">
        <IconChevronDown />
      </div>
    </div>
    <template v-slot:dropdownContent>
      <refonte-item-entity
        v-bind:key="index"
        v-for="(entity, index) in displayedEntreprises.filter((e: Entreprise) =>
          activeEntrepriseIds.includes(e.entrepriseId)
        ) as Entreprise[]"
        v-on:click="
          (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            toggleEntreprise(entity.entrepriseId);
          }
        "
        v-bind:entity="entity"
        v-bind:admin="activeRubrique.admin"
        v-bind:active="true"
        v-bind:checkbox="true"
        class="my-[1px]" />
      <refonte-separator
        v-if="
          displayedEntreprises.filter((e: Entreprise) =>
            activeEntrepriseIds.includes(e.entrepriseId)
          ).length
        "
        class="px-[30px]" />
      <refonte-item-all-entities
        v-if="activeEntrepriseIds.length > 0"
        v-on:click="
          (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            unselectEntreprises();
          }
        "
        v-bind:admin="activeRubrique.admin"
        class="my-[1px]" />
      <refonte-item-entity
        v-bind:key="index"
        v-for="(entity, index) in displayedEntreprises.filter(
          (e: Entreprise) =>
            !activeEntrepriseIds.includes(e.entrepriseId) &&
            filterBySearch(e, searchValue)
        ) as Entreprise[]"
        v-on:click="
          (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            toggleEntreprise(entity.entrepriseId);
          }
        "
        v-bind:entity="entity"
        v-bind:admin="activeRubrique.admin"
        v-bind:active="false"
        v-bind:checkbox="true"
        class="my-[1px]" />
      <div
        class="refonte-item-no-result"
        v-if="
          searchValue != '' &&
          displayedEntreprises.filter(
            (e: Entreprise) =>
              !activeEntrepriseIds.includes(e.entrepriseId) &&
              filterBySearch(e, searchValue)
          ).length === 0
        ">
        Aucun résultat trouvé pour votre recherche
      </div>
    </template>
  </refonte-dropdown>
  <div
    v-else
    class="refonte-header-scope"
    v-bind:class="activeRubrique.admin ? 'refonte-header-scope-admin' : ''">
    <div class="header-scope-icon">
      <IconEstablishments />
    </div>
    <refonte-item-entity
      v-if="selectedEntities[0]"
      v-bind:entity="selectedEntities[0]"
      v-bind:light="true"
      v-bind:admin="activeRubrique.admin" />
  </div>
</template>

<script setup lang="ts">
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconEstablishments from '@/assets/img/refonte/icons/menu/icon-entities.svg?component';
  import { computed, ComputedRef, Ref, ref, watch } from 'vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteItemEntity from '@/components/refonte/items/refonte-item-entity.vue';
  import RefonteItemAllEntities from '@/components/refonte/items/refonte-item-all-entities.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { storeToRefs } from 'pinia';
  import { useRoute, useRouter } from 'vue-router';
  import { Entreprise } from '@/types/gestion/entreprise';
  import { useArborescenceStore } from '@/stores/arborescence';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';

  const entrepriseStore = useEntrepriseStore();

  const { entreprises, activeEntrepriseIds } = storeToRefs(entrepriseStore);

  const selectedEntities: ComputedRef<Entreprise[]> = computed(() => {
    const activeEntities =
      entreprises.value.length === 1
        ? [entreprises.value[0]]
        : entreprises.value.filter((e: Entreprise) =>
            activeEntrepriseIds.value.includes(e.entrepriseId)
          );
    return activeEntities.length > 0 ? activeEntities : [];
  });

  const searchValue: Ref<string> = ref('');

  function setSearchValue(value: string) {
    searchValue.value = value;
  }

  function filterBySearch(e: Entreprise, value: string) {
    return (
      value === '' ||
      (e.denomination + e.codePostal + e.ville + e.siret)
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }
  const toggleEntreprise = (id: number) => {
    const entreprise: Entreprise | undefined = entreprises.value.find(
      (e: Entreprise) => e.entrepriseId === id
    );
    if (entreprise) {
      arborescenceStore.setSousRubrique(
        'gestion',
        'gestion',
        'contrats',
        'menuHidden',
        !(entreprise as Entreprise).hasContrats
      );

      //TODO Mettre à jour les droits
      /* store.dispatch(MODULES.ESPACE + '/' + ACTION_TYPES.SET_RUBRIQUE, {
        espace: 'entreprise',
        rubrique: 'entreprise',
        name: 'restricted',
        value: !(
          entreprise.droitAdministrateur ||
          entreprise.droitAccesDocuments ||
          entreprise.droitInscription
        )
      });*/
      /*const activeEntreprise = selectedEntity.value;

    if (
       ((activeEntreprise.droitAdministrateur !==
         entreprise.droitAdministrateur ||
         activeEntreprise.droitInscription !== entreprise.droitInscription ||
         activeEntreprise.droitAccesDocuments !==
           entreprise.droitAccesDocuments) &&
         route.params.sub_space_id === 'entreprise' &&
         route.params.space_id === 'entreprise') ||
       route.params.sub_space_id === 'details'
     ) {
       router.push('/entreprise');
     }

     */
    }
    let temporaryEntrepriseIds: number[] = activeEntrepriseIds.value;

    if (temporaryEntrepriseIds.includes(id)) {
      temporaryEntrepriseIds = temporaryEntrepriseIds.filter((e) => e !== id);
    } else {
      temporaryEntrepriseIds =
        temporaryEntrepriseIds.length > 0
          ? [...temporaryEntrepriseIds, id]
          : [id];
    }
    entrepriseStore.setActiveEntrepriseIds(temporaryEntrepriseIds);
    setSearchValue('');
  };
  function unselectEntreprises() {
    entrepriseStore.setActiveEntrepriseIds([]);
    setSearchValue('');
  }
  const router = useRouter();
  const route = useRoute();
  watch(activeEntrepriseIds, async () => {
    if (activeEntrepriseIds.value.length > 0) {
      await router.push({
        query: {
          ...route.query,
          clientIds: activeEntrepriseIds.value.toString()
        }
      });
    } else {
      await router.push({
        query: { ...route.query, clientIds: undefined }
      });
    }
  });

  const arborescenceStore = useArborescenceStore();
  const { activeRubrique } = storeToRefs(arborescenceStore);

  const displayedEntreprises = computed(() =>
    entreprises.value.filter(
      (e: Entreprise) => !activeRubrique.value.admin || e.droitAdministrateur
    )
  );
</script>

<style lang="scss" scoped>
  .refonte-item-no-result {
    @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
    font-size: 13px;
    font-weight: 400;
    line-height: 125%;
    gap: 4px;
    padding: 8px;
  }
  .refonte-header-scope {
    @apply flex shrink-0 items-center bg-refonte-pale-violet-2;
    gap: 8px;
    width: 272px;
    max-width: 320px;
    border-radius: 8px;
    padding: 6px 8px;
    height: 64px;

    @media (max-width: 767.98px) {
      @apply w-full;
      padding: 4px 8px !important;
      border-radius: 4px !important;
      height: 56px !important;
    }

    .header-scope-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 24px;
      height: 24px;

      :deep(svg) {
        @apply text-refonte_blue-600;
        width: 24px;
        height: 24px;
      }
    }
    :deep(.refonte-item-all-entities),
    :deep(.refonte-item-entity) {
      @apply cursor-default bg-transparent;
      padding: 0;
      border-radius: 0;

      &:hover {
        @apply bg-transparent;
      }
    }
    .header-scope-chevron {
      @apply hidden;
    }

    &.refonte-header-scope-admin {
      @apply bg-refonte-pale-violet-2;
    }

    &.header-scope-with-entities {
      @apply cursor-pointer;

      &:hover {
        @apply bg-refonte-pale-violet-2;
      }

      &.refonte-header-scope-admin {
        &:hover {
          @apply bg-refonte-pale-violet-3;
        }
      }

      :deep(.refonte-item-all-entities),
      :deep(.refonte-item-entity) {
        @apply cursor-pointer;
      }
      .header-scope-chevron {
        @apply flex shrink-0 items-center justify-center;
        width: 16px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
        }
      }
    }
  }
  .header-scope-dropdown {
    :deep(.dropdown-container) {
      min-width: 320px !important;
      width: auto !important;
      max-width: 320px !important;
    }
  }
  .header-scope-dropdown.dropdown-open {
    .refonte-header-scope {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
