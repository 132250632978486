<template>
  <refonte-filters
    v-if="metadataLoaded"
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.date) ||
      filters_utils.methods.hasValue(filters.datePeriodeFin) ||
      filters_utils.methods.hasValue(filters.datePeriodeDebut) ||
      filters_utils.methods.hasValue(filters.datePeriodeId) ||
      filters_utils.methods.hasValue(filters.action) ||
      filters_utils.methods.hasValue(filters.formationId) ||
      filters_utils.methods.hasValue(filters.contactId) ||
      filters_utils.methods.hasValue(filters.organisateurId) ||
      filters_utils.methods.hasValue(filters.modeId) ||
      filters_utils.methods.hasValue(filters.nbResultats) ||
      filters_utils.methods.hasValue(filters.statutId)
    ">
    <template v-slot:default>
      <div class="filters-group">
        <refonte-filters-item
          v-bind:footer_button_on_click="() => updateDateFilter()"
          v-bind:number="filters.datePeriodeId !== undefined ? 1 : undefined">
          Date de formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="periode in PERIOD_IDS"
              v-bind:on_click="
                () => {
                  resetDateInputFilters();
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
                    periode.id
                  );
                }
              "
              v-bind:key="periode.id">
              {{ periode.label }}
            </refonte-dropdown-item>
            <div class="p-2">
              <refonte-label label="Date" />
              <refonte-datepicker
                v-on:input="(value) => updateDate('date', value)"
                class="p-1"
                placeholder="Date exacte (JJ/MM/YYYY)"
                v-bind:value="date?.date" />
            </div>
            <refonte-separator />
            <div class="p-2">
              <refonte-label label="Période" />
              <refonte-datepicker
                class="p-1"
                placeholder="Début (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('debut', value)"
                v-bind:value="date?.debut" />
              <refonte-datepicker
                class="p-1"
                placeholder="Fin (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('fin', value)"
                v-bind:value="date?.fin" />
            </div>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value: string) => updateParticipantValue(value)"
          v-bind:number="filters.contactId !== undefined ? 1 : undefined">
          Participant
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="participantList.length"
              v-for="participant in participantList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.contactId.name,
                    participant.id
                  )
              "
              v-bind:key="participant.id">
              <div>
                {{ participant.nomPrenom }}
              </div>
              <div>
                {{ participant.email }}
              </div>
            </refonte-dropdown-item>
            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="filters.statutId !== undefined ? 1 : undefined">
          Statut
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="statut in STATUTS"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.statutId.name,
                    statut.id
                  )
              "
              v-bind:key="statut.id">
              {{ statut.label }}
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value) => updateFormationValue(value)"
          v-bind:number="filters.formationId !== undefined ? 1 : undefined">
          Formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="formationList.length"
              v-for="formation in formationList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.formationId.name,
                    formation.id
                  )
              "
              v-bind:key="formation.id">
              {{ formation.titre }}
            </refonte-dropdown-item>

            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value: string) => updateOrganisateurValue(value)"
          v-bind:number="filters.organisateurId !== undefined ? 1 : undefined">
          Organisateur
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="organisateurList.length"
              v-for="organisateur in organisateurList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.organisateurId.name,
                    organisateur.id
                  )
              "
              v-bind:key="organisateur.id">
              {{ organisateur.nom }}
            </refonte-dropdown-item>
            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="filters.action !== undefined ? 1 : undefined">
          Action à réaliser
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="action in ACTIONS"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                    action.id
                  )
              "
              v-bind:key="action.id">
              {{ action.label }}
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="filters.modeId !== undefined ? 1 : undefined">
          Mode
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="mode in MODES"
              v-bind:on_click="
                () =>
                  updateFilter(
                    INSCRIPTIONS_FILTERS_CONFIGURATION.modeId.name,
                    mode.id
                  )
              "
              v-bind:key="mode.id">
              {{ mode.label }}
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
      </div>
      <refonte-filters-item
        v-bind:number="filters.nbResultats !== undefined ? 1 : undefined">
        Afficher
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="option in NB_RESULTAT_OPTIONS"
            v-bind:on_click="
              () =>
                updateFilter(
                  INSCRIPTIONS_FILTERS_CONFIGURATION.nbResultats.name,
                  option.id
                )
            "
            v-bind:key="option.id">
            {{ option.label }}
          </refonte-dropdown-item>
          <refonte-dropdown-item
            v-bind:on_click="
              () =>
                updateFilter(
                  INSCRIPTIONS_FILTERS_CONFIGURATION.nbResultats.name,
                  undefined
                )
            "
            v-bind:key="'tous'">
            Tous
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () => {
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
                updateFilter(filterApplied.id, undefined);
              updateTemporaryDates(filterApplied.id);
            }
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import {
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    useInscriptionFilterStore
  } from '@/stores/inscriptions';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import {
    InscriptionMetadata,
    InscriptionMetadataFormation,
    InscriptionMetadataOrganisateur,
    InscriptionMetadataParticipant
  } from '@/types/gestion/inscriptions-filters-types';
  import { API } from '@/http-common';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import filters_utils from '@/mixin/utils/filters_utils';

  const participantSearchValue = ref<string>('');
  const updateParticipantValue = (value: string) => {
    participantSearchValue.value = value;
  };

  const formationSearchValue = ref<string>('');
  const updateFormationValue = (value: string) => {
    formationSearchValue.value = value;
  };

  const organisateurSearchValue = ref<string>('');
  const updateOrganisateurValue = (value: string) => {
    organisateurSearchValue.value = value;
  };
  const metadataLoaded = ref<boolean>(false);

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDate = (name: string, value: undefined | Date | string) => {
    date.value[name] = value;
  };

  const getLabel = (
    list: { id: string | number; label: string }[],
    id: number | string | undefined,
    name: string
  ) => {
    const item = list && list.length && list.find((i) => i.id == id);
    return item ? filters_utils.methods.formatFilter(name, item.label) : '';
  };
  const getProduitTitre = (id: number | undefined) => {
    const item =
      formationFilterList.value &&
      formationFilterList.value.length &&
      formationFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Formation', item.titre)
      : '';
  };
  const getParticipantNomPrenom = (id: number | undefined) => {
    const item =
      participantFilterList.value &&
      participantFilterList.value.length &&
      participantFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Participant', item.nomPrenom)
      : '';
  };

  const ACTIONS = computed(() => [
    {
      id: 'CONVENTIONS_A_SIGNER',
      label: 'Conventions à signer'
    },
    {
      id: 'PIF_A_SIGNER',
      label: 'PIF à signer'
    },
    {
      id: 'T_POS_NON_REALISE',
      label: 'T. Pos. non réalisé'
    }
  ]);

  const MODES = computed(() => [
    {
      id: 1,
      label: 'Inter'
    },
    {
      id: 2,
      label: 'Intra'
    }
  ]);

  const STATUTS = computed(() => [
    {
      id: -1,
      label: 'Part. confirmé'
    },
    {
      id: 1,
      label: 'En attente'
    },
    {
      id: 2,
      label: 'Confirmé'
    },
    {
      id: 3,
      label: 'Refusé/Suspendu'
    },
    {
      id: 4,
      label: 'Annulé'
    },
    {
      id: 104,
      label: 'Transféré'
    }
  ]);

  const NB_RESULTAT_OPTIONS = computed(() => [
    {
      id: 100,
      label: '100'
    }
  ]);

  const getOrganisateurNom = (id: number | undefined) => {
    const item =
      organisateurFilterList.value &&
      organisateurFilterList.value.length &&
      organisateurFilterList.value.find((v) => v.id == id);
    return item
      ? filters_utils.methods.formatFilter('Organisme de formation', item.nom)
      : '';
  };

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);
  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const inscriptionFilterStore = useInscriptionFilterStore();
  const { filters } = storeToRefs(inscriptionFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean
  ) => {
    inscriptionFilterStore.setFilter(name, value);
  };
  const date = ref<DateFilter>({
    date: filters.value.date,
    debut: filters.value.datePeriodeDebut,
    fin: filters.value.datePeriodeFin,
    periodeId: filters.value.datePeriodeId
  });

  const resetFilters = () => {
    inscriptionFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    date.value = { ...dateFilterDefaut };
  };
  const resetDateInputFilters = () => {
    updateFilter(INSCRIPTIONS_FILTERS_CONFIGURATION.date.name, undefined);
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      undefined
    );
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      undefined
    );
    date.value = {
      ...date.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const updateDateFilter = () => {
    const dateDebutValue = date.value;
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
      undefined
    );
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
      dateDebutValue.date
    );
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      dateDebutValue.debut
    );
    updateFilter(
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      dateDebutValue.fin
    );
  };
  const updateTemporaryDates = (filterId: string) => {
    switch (filterId) {
      case INSCRIPTIONS_FILTERS_CONFIGURATION.date.name:
        updateDate('date', undefined);
        break;
      case INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name:
        updateDate('debut', undefined);
        break;
      case INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name:
        updateDate('fin', undefined);
        break;
      default:
        break;
    }
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        formatDate(filters.value.date)
      )
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        getPeriodFilterApply(filters.value.datePeriodeId)
      )
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Après le
          ${formatDate(filters.value.datePeriodeDebut)}`
      )
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Avant le
          ${formatDate(filters.value.datePeriodeFin)}`
      )
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.formationId.name,
      value: getProduitTitre(filters.value.formationId)
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.contactId.name,
      value: getParticipantNomPrenom(filters.value.contactId)
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.organisateurId.name,
      value: getOrganisateurNom(filters.value.organisateurId)
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
      value: getLabel(ACTIONS.value, filters.value.action, 'Action à réaliser')
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.modeId.name,
      value: getLabel(MODES.value, filters.value.modeId, 'Mode')
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.statutId.name,
      value: getLabel(STATUTS.value, filters.value.statutId, 'Statut')
    },
    {
      id: INSCRIPTIONS_FILTERS_CONFIGURATION.nbResultats.name,
      value: getLabel(
        NB_RESULTAT_OPTIONS.value,
        filters.value.nbResultats,
        'Résultats affichés'
      )
    }
  ]);
  const participantFilterList = ref<InscriptionMetadataParticipant[]>([]);

  const participantList = computed(() =>
    participantFilterList.value && participantFilterList.value.length
      ? participantFilterList.value.filter(
          (p) =>
            participantSearchValue.value.length < 3 ||
            p.nomPrenom
              .toLowerCase()
              .includes(participantSearchValue.value.toLowerCase())
        )
      : []
  );

  const formationFilterList = ref<InscriptionMetadataFormation[]>([]);

  const formationList = computed(() =>
    formationFilterList.value && formationFilterList.value.length
      ? formationFilterList.value.filter(
          (f) =>
            formationSearchValue.value.length < 3 ||
            f.titre
              .toLowerCase()
              .includes(formationSearchValue.value.toLowerCase())
        )
      : []
  );

  const organisateurFilterList = ref<InscriptionMetadataOrganisateur[]>([]);

  const organisateurList = computed(() =>
    organisateurFilterList.value && organisateurFilterList.value.length
      ? organisateurFilterList.value.filter(
          (f) =>
            organisateurSearchValue.value.length < 3 ||
            f.nom
              .toLowerCase()
              .includes(organisateurSearchValue.value.toLowerCase())
        )
      : []
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/inscriptions/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    );

    if (response && response.data) {
      const data: InscriptionMetadata = response.data;
      formationFilterList.value = data.formations ?? [];
      participantFilterList.value = data.participants ?? [];
      organisateurFilterList.value = data.organisateurs ?? [];
    }
    metadataLoaded.value = true;
  };

  watch(activeClientIds, () => {
    resetFilters();
    fetchMetadata();
  });

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped>
  .filters-group {
    @apply flex flex-grow flex-wrap items-center;
    gap: 8px;
  }
</style>
