import { API } from '@/http-common.js';
import http_functions from '@/mixin/http_functions';
import {
    buildDate,
    buildDocumentStatut,
    buildProduitTypeLabel,
    buildProduitTypeShortcut
} from '@/stores/utils';
import { Filters } from '@/types/gestion/inscriptions-filters-types';
import {
    Inscription,
    InscriptionResponse
} from '@/types/gestion/inscriptions-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

export const INSCRIPTIONS_STATUTS_IDS = [-1, 1, 2, 3];
export const INSCRIPTIONS_ANNULEES_STATUTS_IDS = [4, 104];
export const INSCRIPTIONS_EN_ATTENTE_STATUTS_IDS = [1];

const noFilterState: Filters = {
    clientIds: [],
    statutIds: INSCRIPTIONS_STATUTS_IDS,
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    contactId: undefined,
    action: undefined,
    organisateurId: undefined,
    modeId: undefined,
    nbResultats: 100
};

const filterInitialState: Filters = {
    clientIds: [],
    statutIds: INSCRIPTIONS_STATUTS_IDS,
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    contactId: undefined,
    action: undefined,
    organisateurId: undefined,
    modeId: undefined,
    nbResultats: 100
};

export const INSCRIPTIONS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    statutIds: {
        name: 'statutIds',
        isArray: true
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    contactId: {
        name: 'contactId',
        isArray: false
    },
    action: {
        name: 'action',
        isArray: false
    },
    organisateurId: {
        name: 'organisateurId',
        isArray: false
    },
    modeId: {
        name: 'modeId',
        isArray: false
    },
    nbResultats: {
        name: 'nbResultats',
        isArray: false
    },
    statutId: {
        name: 'statutId',
        isArray: false
    }
};

export const useInscriptionFilterStore = defineStore(
    'inscriptions-filter',
    () => {
        const filters = ref<Filters>(filterInitialState);

        const setFilter = (name: string, value: any) => {
            filters.value = { ...filters.value, [name]: value };
        };
        const resetFilters = () => {
            filters.value = noFilterState;
        };

        const parsePayload = (payload: LocationQuery): Filters => {
            const newPayload = { ...payload };

            Object.keys(newPayload).forEach((key) => {
                const filter = INSCRIPTIONS_FILTERS_CONFIGURATION[key] as any;

                if (filter && filter.isArray) {
                    newPayload[key] = newPayload[key].split(',');
                }
                if (
                    [
                        INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
                        INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut
                            .name,
                        INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
                    ].includes(key)
                ) {
                    newPayload[key] = new Date(newPayload[key]);
                }
            });

            return newPayload;
        };

        const initFiltersFromQuery = (payload: LocationQuery) => {
            const parsedPayload = parsePayload(payload) as Filters;
            if (payload && Object.keys(payload).length > 0) {
                filters.value = {
                    ...noFilterState,
                    ...parsedPayload
                };
            } else {
                filters.value = {
                    ...filterInitialState,
                    ...parsedPayload
                };
            }
        };

        return {
            resetFilters,
            setFilter,
            filters,
            initFiltersFromQuery
        };
    }
);

export const useInscriptionStore = defineStore('inscriptions', () => {
    const inscriptions = ref<Inscription[]>([]);

    const exportCsv = async (query: Filters) => {
        http_functions.methods.downloadFile(
            `client/v2/inscriptions/exporter${query ? `?${new URLSearchParams(query).toString()}` : ''}`,
            'export.csv'
        );
    };

    const fetchInscriptions = async (query: Filters) => {
        const response = await API.get(`client/v2/inscriptions`, {
            params: query
        });
        if (response?.data) {
            inscriptions.value = response?.data.map((i: InscriptionResponse) =>
                mapInscription(i)
            );
        }
    };

    const mapInscription = (i: InscriptionResponse): Inscription => {
        return {
            ...i,
            clientDenomination: i?.client?.denomination,
            clientSiret: i.client.siret ?? undefined,
            participantNomPrenom: i.nomPrenom,
            organisateur: i.organisateur,
            typeShortcut: addSessionTypeShortcut(
                buildProduitTypeShortcut(
                    i.produit.type.id,
                    i.produit.lienElearning
                ),
                i.sessionStatut?.id
            ),
            typeLabel: addSessionTypeLabel(
                buildProduitTypeLabel(
                    i.produit.type.label,
                    i.produit.lienElearning
                ),
                i.sessionStatut?.id
            ),
            modeLabel: i?.mode?.label,
            statutLabel:
                i?.statut?.id === -1
                    ? 'Partiellement confirmé'
                    : i.statut.label,
            sessionStatutLabel: i.sessionStatut?.label,
            ...(i.convention?.id && {
                conventionStatut: buildDocumentStatut(
                    i.convention?.disponible,
                    i.convention?.signee,
                    i.convention?.validee
                )
            }),
            ...(i.pif?.id && {
                pifStatut: buildDocumentStatut(
                    i.pif?.disponible,
                    i.pif?.signee,
                    i.pif?.validee
                )
            }),
            ...(i.hasCertificats && {
                certificatLabel: 'Télécharger'
            }),
            ...(i.testPositionnement && {
                testPositionnementStatut: buildTestPositionnementStatut(
                    i.testPositionnement?.disponible,
                    i.testPositionnement?.realise
                )
            }),
            clientId: i.client.id,
            titre: `${i.produit.titre}${i.familleStageEC ? ' (Stage EC)' : ''}${i.familleStageCAC ? ' (Stage CAC)' : ''}`,
            datesFormations: buildDate(i.dateDebut, i.dateFin),
            disabled: i.sessionStatut?.id === 5
        };
    };

    const buildTestPositionnementStatut = (
        disponible?: boolean,
        realise?: boolean
    ) => {
        if (!disponible) {
            return 'Non requis';
        } else if (disponible && !realise) {
            return 'Non réalisé';
        } else if (realise) {
            return 'Réalisé';
        }
    };

    const addSessionTypeShortcut = (
        flags: string[],
        sessionStatutId: number
    ) => {
        return sessionStatutId === 5 ? [...flags, 'annulee'] : flags;
    };

    const addSessionTypeLabel = (flags: string[], sessionStatutId: number) => {
        return sessionStatutId === 5 ? [...flags, 'Annulée'] : flags;
    };

    return {
        inscriptions,
        fetchInscriptions,
        exportCsv
    };
});
