import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';
const espaces_list = {
    account: {
        menuHidden: true,
        id: 'account',
        label: 'Mon compte',
        rubriques: {
            informations: {
                id: 'informations',
                label: 'Informations personnelles'
            },
            preferences: {
                id: 'preferences',
                label: 'Préférences'
            }
        },
        beforeNewEspaceEntreprise: false,
        connexionPriority: 1000
    },
    participant: {
        id: 'participant',
        label: 'Espace participant',
        rubriques: {
            session: {
                id: 'session',
                label: 'Mes sessions',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Prochaines sessions'
                    },
                    sessionsdone: {
                        id: 'sessionsdone',
                        label: 'Sessions terminées'
                    }
                }
            },
            commandes: {
                id: 'commandes',
                label: 'Commandes',
                sous_rubriques: {
                    commandes: {
                        id: 'commandes',
                        label: 'Commandes réalisées'
                    },
                    paniers: {
                        id: 'paniers',
                        label: 'Paniers en cours'
                    }
                }
            },
            factures: {
                id: 'factures',
                label: 'Factures'
            },
            details: {
                id: 'details',
                label: 'Détails de la session',
                menuHidden: true
            }
        },
        connexionPriority: 12,
        restricted: true,
        color: 'jinius-red',
        beforeNewEspaceEntreprise: true
    },

    formateur: {
        id: 'formateur',
        label: 'Espace formateur',
        rubriques: {
            session: {
                id: 'session',
                label: 'Mes sessions',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Prochaines sessions'
                    },
                    sessionsdone: {
                        id: 'sessionsdone',
                        label: 'Sessions terminées'
                    }
                }
            },
            candidature: {
                id: 'candidature',
                label: 'Mes candidatures',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Navettes en cours'
                    },
                    candidatures: {
                        id: 'candidatures',
                        label: 'Mes candidatures en cours'
                    }
                }
            },
            cv: {
                id: 'cv',
                label: 'Mon CV'
            },
            agenda: {
                id: 'agenda',
                label: 'Agenda'
            },
            appreciations: {
                id: 'appreciations',
                label: 'Appreciations',
                sous_rubriques: {
                    campagnes: {
                        id: 'campagnes',
                        label: 'Par campagne'
                    },
                    formations: {
                        id: 'formations',
                        label: 'Par formation'
                    },
                    sessions: {
                        id: 'sessions',
                        label: 'Par session'
                    }
                }
            },
            contrats: {
                id: 'contrats',
                label: 'Contrats'
            },
            details: {
                id: 'details',
                label: 'Détails de la session',
                menuHidden: true
            }
        },
        connexionPriority: 1,
        restricted: true,
        color: 'jinius-green'
    },
    controleurDeStageEC: {
        id: 'controleurDeStageEC',
        label: 'Espace contrôleur de stage EC',
        rubriques: {
            stagiaires: {
                id: 'stagiaires',
                label: 'Mes stagiaires'
            }
        },
        connexionPriority: 4,
        restricted: true,
        color: 'jinius-purple'
    },
    controleurDeStageCAC: {
        id: 'controleurDeStageCAC',
        label: 'Espace contrôleur de stage CAC',
        rubriques: {
            stagiaires: {
                id: 'stagiaires',
                label: 'Mes stagiaires'
            }
        },
        connexionPriority: 4,
        restricted: true,
        color: 'jinius-rose'
    },
    maitreDeStage: {
        id: 'maitreDeStage',
        label: 'Espace maître de stage',
        rubriques: {
            stagiaires: {
                id: 'stagiaires',
                label: 'Mes stagiaires'
            }
        },
        connexionPriority: 5,
        restricted: true,
        color: 'jinius-yellow'
    },
    stagiaire: {
        id: 'stagiaire',
        label: 'Espace stagiaire',
        rubriques: {
            elearning: {
                id: 'elearning',
                label: 'Mes informations'
            },
            session: {
                id: 'session',
                label: 'Mes sessions',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Prochaines sessions'
                    },
                    sessionsdone: {
                        id: 'sessionsdone',
                        label: 'Sessions terminées'
                    }
                }
            },
            details: {
                id: 'details',
                label: 'Détails de la session',
                menuHidden: true
            }
        },
        connexionPriority: 10,
        restricted: true,
        color: 'jinius-pink'
    },
    stagiaireCAC: {
        id: 'stagiaireCAC',
        label: 'Espace stagiaire CAC',
        rubriques: {
            informations: {
                id: 'informations',
                label: 'Mes informations'
            },
            session: {
                id: 'session',
                label: 'Mes sessions',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Prochaines sessions'
                    },
                    sessionsdone: {
                        id: 'sessionsdone',
                        label: 'Sessions terminées'
                    }
                }
            },
            formations: {
                id: 'formations',
                label: 'Mes formations libres'
            },
            details: {
                id: 'details',
                label: 'Détails de la session',
                menuHidden: true
            }
        },
        connexionPriority: 11,
        restricted: true,
        color: 'jinius-pink'
    },
    entreprise: {
        id: 'entreprise',
        label: 'Ancien espace gestion (espace entreprise)',
        rubriques: {
            inscriptions: {
                id: 'inscriptions',
                label: 'Inscriptions',
                sous_rubriques: {
                    sessions: {
                        id: 'sessions',
                        label: 'Prochaines sessions'
                    },
                    sessionsdone: {
                        id: 'sessionsdone',
                        label: 'Sessions terminées'
                    }
                }
            },
            commandes: {
                id: 'commandes',
                label: 'Commandes',
                sous_rubriques: {
                    commandes: {
                        id: 'commandes',
                        label: 'Commandes réalisées'
                    },
                    paniers: {
                        id: 'paniers',
                        label: 'Paniers en cours'
                    }
                }
            },
            factures: {
                id: 'factures',
                label: 'Factures'
            },
            entreprise: {
                id: 'entreprise',
                label: 'Mon entreprise',
                sous_rubriques: {
                    participants: {
                        id: 'participants',
                        label: 'Annuaire',
                        isAdmin: true
                    },
                    facturation: {
                        id: 'facturation',
                        label: 'Mes établissements',
                        isAdmin: true
                    },
                    informations: {
                        id: 'informations',
                        label: 'Informations générales'
                    },
                    interlocutors: {
                        id: 'interlocutors',
                        label: 'Interlocuteurs entreprise',
                        isAdmin: true
                    },
                    satisfaction: {
                        id: 'satisfaction',
                        label: 'Satisfaction',
                        isAdmin: true
                    }
                }
            },
            contrats: {
                id: 'contrats',
                label: 'Contrats'
            },
            details: {
                id: 'details',
                label: 'Détails de la session',
                menuHidden: true
            }
        },
        connexionPriority: 3,
        restricted: true,
        color: 'jinius-blue'
    }
};
const state = { espaces_list };

const mutations = {
    [MUTATION_TYPES.SET_ESPACE](state, { espace, name, value }) {
        state.espaces_list[espace] = {
            ...state.espaces_list[espace],
            [name]: value
        };
    },
    [MUTATION_TYPES.SET_RUBRIQUE](state, { espace, rubrique, name, value }) {
        state.espaces_list[espace].rubriques[rubrique] = {
            ...state.espaces_list[espace].rubriques[rubrique],
            [name]: value
        };
    }
};

const actions = {
    async [ACTION_TYPES.SET_ESPACE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_ESPACE, payload);
    },
    [ACTION_TYPES.SET_RUBRIQUE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_RUBRIQUE, payload);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
