<template>
  <div
    class="refonte-table"
    v-bind:class="props.class">
    <refonte-table-row
      v-for="(row, rowIndex) in data"
      v-bind:key="props.table_index + rowIndex"
      v-bind:table_small="props.table_small"
      v-bind:selectable="props.selectable"
      v-bind:disabled="row.disabled"
      v-bind:row_selected="row.selected"
      v-on:select="(value: boolean) => props.select_row(row, value)"
      v-bind:row="row"
      v-bind:on_details="
        props.on_details ? () => props.on_details(row) : undefined
      "
      v-bind:on_action_click="
        props.on_action_click ? () => props.on_action_click(row) : undefined
      "
      v-bind:on_action_disabled="
        props.on_action_disabled
          ? () => props.on_action_disabled(row)
          : undefined
      "
      v-bind:on_action_variant="
        props.on_action_variant ? props.on_action_variant : undefined
      "
      v-bind:on_action_label="
        props.on_action_label ? () => props.on_action_label(row) : undefined
      ">
      <refonte-table-item
        v-if="props.data_etablissement"
        key="etablissement"
        v-bind:width="props.data_etablissement_width ?? 165"
        v-bind:label="`Etablissement${props.data_etablissement_status && props.data_etablissement_status(row) ? ` - ${props.data_etablissement_status(row)}` : ''}`"
        v-bind:status="
          props.data_etablissement_pastille
            ? props.data_etablissement_pastille(row)
            : undefined
        "
        v-bind:sub_text="
          props.data_etablissement_siret && row[props.data_etablissement_siret]
            ? `SIRET ${row[props.data_etablissement_siret]}`
            : undefined
        ">
        <template v-slot:content>
          <span>
            {{
              row[props.data_etablissement]
                ? row[props.data_etablissement]
                : '-'
            }}
          </span>
        </template>
      </refonte-table-item>
      <refonte-table-item
        v-for="(column, colIndex) in columns"
        v-bind:key="colIndex"
        v-bind:width="
          column.min_width && breakpoints.w >= 1400 && breakpoints.w < 1920
            ? column.min_width
            : column.width
        "
        v-bind:size="column.size"
        v-bind:pending="
          column.is_pending
            ? column.is_pending(row)
            : !row[column.content_index]
        "
        v-bind:on_click="
          column.on_click &&
          (column.on_click_disabled === undefined ||
            (column.on_click_disabled && !column.on_click_disabled(row)))
            ? () => column.on_click && column.on_click(row)
            : undefined
        "
        v-bind:tooltip="column.tooltip"
        v-bind:no_label="!!column.content_button_icon"
        v-bind:label="column.label ? column.label : undefined"
        v-bind:label_icon="column.label_icon"
        v-bind:status="column.status ? column.status(row) : undefined"
        v-bind:icon="column.content_icon ? column.content_icon(row) : undefined"
        v-bind:icon_black="
          column.content_icon_black ? column.content_icon_black : false
        "
        v-bind:sub_text="
          column.content_sub_text && column.content_sub_text(row)
        "
        v-bind:sub_link_text="
          column.content_sub_link_text && column.content_sub_link_text(row)
        "
        v-bind:sub_link_class="column.content_sub_link_class"
        v-bind:sub_link_prefix="column.content_sub_link_prefix"
        v-bind:sub_link_path="
          column.content_sub_link_path && column.content_sub_link_path(row)
        "
        v-bind:class="`${column.class ? column.class(row) : ''} ${column.content_button_icon ? '!flex-grow-0' : ''} ${column.displayBreakpoint && breakpoints.w < column.displayBreakpoint ? '!hidden' : ''}`">
        <template
          v-if="
            (column.tags_count && row[column.tags_count]) ||
            (column.tags_type &&
              row[column.tags_type] &&
              column.tags_type_label &&
              row[column.tags_type_label])
          "
          v-slot:itemTags>
          <refonte-tag
            v-if="column.tags_count && row[column.tags_count]"
            v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{
              row[column.tags_count]
            }}</refonte-number>
          </refonte-tag>
          <refonte-tag
            v-if="
              column.tags_type &&
              row[column.tags_type].length &&
              column.tags_type_label &&
              row[column.tags_type_label].length
            "
            v-for="(tagItem, tagIndex) in row[column.tags_type]"
            v-bind:key="props.table_index + rowIndex + tagIndex"
            v-bind:variant="tagItem">
            {{ row[column.tags_type_label][tagIndex] }}
          </refonte-tag>
        </template>
        <template
          v-if="
            (column.date && row[column.date]) ||
            (column.dates && row[column.dates]) ||
            (column.start_date &&
              row[column.start_date] &&
              column.end_date &&
              row[column.end_date]) ||
            (column.start_time &&
              row[column.start_time] &&
              column.end_time &&
              row[column.end_time])
          "
          v-slot:itemDates>
          <refonte-date
            v-if="column.date && row[column.date]"
            v-bind:date="row[column.date]" />
          <refonte-date
            v-if="column.dates && row[column.dates]"
            v-bind:dates="row[column.dates]" />
          <refonte-date-range
            v-if="
              column.start_date &&
              row[column.start_date] &&
              column.end_date &&
              row[column.end_date]
            "
            v-bind:start_date="row[column.start_date]"
            v-bind:end_date="row[column.end_date]" />
          <refonte-time
            v-if="
              column.start_time &&
              row[column.start_time] &&
              column.end_time &&
              row[column.end_time]
            "
            v-bind:start_time="row[column.start_time]"
            v-bind:end_time="row[column.end_time]" />
        </template>
        <template v-slot:content>
          <div v-if="column.content_custom">
            <component v-bind:is="column.content_custom(row)" />
          </div>
          <div
            v-else-if="
              column.content_button_icon &&
              column.content_button_icon(row) !== undefined
            "
            class="refonte-table-item-button">
            <refonte-button
              size="sm"
              v-bind:variant="
                column.content_button_variant
                  ? column.content_button_variant(row)
                  : 'primary'
              "
              v-bind:title="
                column.content_button_title && column.content_button_title(row)
                  ? column.content_button_title(row)
                  : ' '
              "
              v-bind:on_click="
                column.content_button_on_click
                  ? (e) => {
                      column.content_button_on_click(row);
                      e.stopPropagation();
                    }
                  : undefined
              "
              v-bind:disabled="
                column.content_button_disabled &&
                column.content_button_disabled(row)
              ">
              <component v-bind:is="column.content_button_icon(row)" />
            </refonte-button>
          </div>
          <span
            v-else-if="
              column.content_button_icon &&
              column.content_button_icon(row) === undefined
            "></span>
          <span v-else>
            {{ row[column.content_index] ? row[column.content_index] : '-' }}
          </span>
        </template>
      </refonte-table-item>
    </refonte-table-row>
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteTableRow from '@/components/refonte/tables/refonte-table-row.vue';
  import RefonteTableItem from '@/components/refonte/tables/refonte-table-item.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteDate from '@/components/refonte/dates/refonte-date.vue';
  import RefonteDateRange from '@/components/refonte/dates/refonte-date-range.vue';
  import RefonteTime from '@/components/refonte/dates/refonte-time.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import breakpoints from '@/vue-tailwind-breakpoints';

  export interface Column {
    width: number;
    min_width?: string;
    size?: string;
    displayBreakpoint?: number;
    is_pending?: (row: any) => boolean;
    on_click?: (row: any) => void;
    on_click_disabled?: (row: any) => boolean;
    tooltip?: string;
    label?: string;
    label_icon?: Element;
    status: (row: any) => string;
    tags_count: string;
    tags_type: string[];
    tags_type_label: string[];
    date: string;
    dates: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    content_index: string;
    content_icon?: (row: any) => Element;
    content_icon_black?: boolean;
    content_custom?: (row: any) => Element;
    content_button_icon?: (row: any) => Element | undefined;
    content_button_on_click?: (row: any) => void;
    content_button_variant?: (row: any) => string;
    content_button_title?: (row: any) => string;
    content_button_disabled?: (row: any) => boolean;
    content_sub_text?: (row: any) => string;
    content_sub_link_text?: (row: any) => string;
    content_sub_link_class?: string;
    content_sub_link_prefix?: string;
    content_sub_link_path?: (row: any) => string;
    class?: (row: any) => string;
  }

  const props = withDefaults(
    defineProps<{
      table_index: string;
      table_small?: boolean;
      selectable?: boolean;
      select_row?: (row: any, value: boolean) => void;
      on_details?: (row: any) => void;
      on_action_click?: (row: any) => void;
      on_action_disabled?: (row: any) => boolean;
      on_action_variant?: string;
      on_action_label?: (row: any) => string;
      columns: Column[];
      data: Object[];
      data_etablissement?: string;
      data_etablissement_siret?: string;
      data_etablissement_width?: number;
      data_etablissement_pastille?: (row: any) => string;
      data_etablissement_status?: (row: any) => string | undefined;
      class?: string;
    }>(),
    {
      selectable: false,
      table_small: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-table {
    @apply flex flex-col;
    gap: 2px;
    padding-bottom: 12px;
  }
  .refonte-table-item-button {
    @apply flex items-end;
    min-height: 42px;

    :deep(.refonte-btn.btn-sm) {
      padding: 5px;

      svg {
        min-width: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
</style>
