<template>
  <section class="section__primary">
    <h1 class="section__title">Informations personnelles</h1>
    <div class="section__body relative">
      <div class="mb-8 lg:absolute lg:right-0 lg:top-0 lg:mb-0">
        <label class="mb-2 flex text-sm font-bold leading-none text-accent-500">
          <span class="mr-1 text-jinius-red">*</span>
          <span>Modifier mon nom, prénom, adresse email et mot de passe</span>
        </label>
        <a
          target="_blank"
          v-bind:href="myJiniusAccount"
          class="inline-flex w-full items-center justify-center rounded border border-accent-200 bg-white px-3 pb-1.5 pt-2 text-xs font-bold leading-none text-jinius-dark-green shadow-sm transition duration-150 ease-in-out hover:bg-accent-100 active:shadow-inner sm:w-auto sm:px-6 sm:text-base lg:w-full">
          <span class="pr-1.5"> Gestion de mon compte </span>
          <img
            v-bind:src="fetchLogo"
            alt="Jinius"
            class="mt-[0.15rem] inline-flex w-12" />
          <span class="pl-1 text-core-600">CONNECT</span>
        </a>
      </div>
      <div class="form__item">
        <select-box
          v-bind:value="
            civilites.find((civilite) => account_form.civilite === civilite.id)
          "
          v-on:input="(value) => set_attribute(value.id, 'civilite')"
          v-bind:categories="civilites"
          label="Civilité"
          placeholder="Civilité"
          no_capitalize_label
          not_required_label
          class_name="no-box"
          tracking="id" />
      </div>

      <div class="form__item">
        <input-box
          v-tooltip.right="{
            content: `<div class='w-80'>
            Pour modifier votre <span class='font-bold'>Nom d'usage</span> cliquez à droite sur le bouton <span class='font-bold'>&laquo;Gestion de mon compte Jinius CONNECT&raquo;</span>
            </div>`
          }"
          disabled
          class_name="no-box"
          name="nomUsage"
          label="Nom d'usage"
          placeholder="Nom d'usage"
          format="uppercase"
          v-bind:maxlength="100"
          v-bind:value="account_form.nomUsage"
          v-on:input="set_attribute($event, 'nomUsage')"
          v-bind:class="{ 'input-error': v$.accountForm.nomUsage.$error }"
          required />
      </div>

      <div class="form__item">
        <input-box
          v-tooltip.right="{
            content: `<div class='w-80'>
            Pour modifier votre <span class='font-bold'>Prénom</span> cliquez à droite sur le bouton <span class='font-bold'>&laquo;Gestion de mon compte Jinius CONNECT&raquo;</span>
            </div>`
          }"
          disabled
          class_name="no-box"
          name="prenom"
          label="Prénom"
          placeholder="Prénom"
          format="capitalizeFirstLetter"
          v-bind:maxlength="100"
          v-bind:value="account_form.prenom"
          v-on:input="set_attribute($event, 'prenom')"
          v-bind:class="{ 'input-error': v$.accountForm.prenom.$error }"
          required />
      </div>

      <div class="form__item">
        <input-box
          class_name="no-box"
          label="Nom de naissance"
          placeholder="Nom de naissance"
          name="nomNaissance"
          not_required_label
          v-bind:maxlength="30"
          v-bind:value="account_form.nomNaissance"
          v-on:input="set_attribute($event, 'nomNaissance')" />
      </div>

      <div class="form__item">
        <datepicker-box
          format="dd/MM/yyyy"
          class_name="no-box"
          label="Date de naissance"
          placeholder="Saisie manuelle (jj/mm/aaaa) ou Sélection dans le calendrier"
          not_required_label
          show_clear_button
          left_icon="calendar-alt"
          v-bind:value="account_form.dateDeNaissance"
          v-bind:min_date="new Date(1900, 1, 1)"
          v-bind:max_date="RANGE_TODAY_DATE"
          v-on:input="set_attribute($event, 'dateDeNaissance')" />
      </div>
    </div>
  </section>
</template>
<script>
  import { civilites } from '@/assets/data/data_form';
  import datepickerBox from '@/components/utils/datepicker-box';
  import inputBox from '@/components/utils/input-box.vue';
  import selectBox from '@/components/utils/select-box';

  const TODAY_DATE = new Date();
  const RANGE_TODAY_DATE = new Date();

  RANGE_TODAY_DATE.setDate(TODAY_DATE.getDate() - 1);

  export default {
    components: {
      inputBox,
      datepickerBox,
      selectBox
    },
    props: ['v$', 'set_attribute', 'account_form'],
    data() {
      return {
        RANGE_TODAY_DATE
      };
    },
    computed: {
      civilites: () => civilites,
      myJiniusAccount() {
        return `${import.meta.env.VITE_API_AUTH}/account/redirect`;
      },
      fetchLogo() {
        return import.meta.env.VITE_LOGO;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
