import { API } from '@/http-common.js';
import {
    buildDate,
    buildDocumentStatut,
    buildProduitTypeLabel,
    buildProduitTypeShortcut
} from '@/stores/utils';
import { Filters } from '@/types/gestion/sessions-filters-types';
import { Session, SessionResponse } from '@/types/gestion/sessions-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    organisateurId: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    organisateurId: undefined
};

export const SESSIONS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    organisateurId: {
        name: 'organisateurId',
        isArray: false
    }
};

export const useSessionFilterStore = defineStore('sessions-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = SESSIONS_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
            if (
                [
                    SESSIONS_FILTERS_CONFIGURATION.date.name,
                    SESSIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
                    SESSIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
                ].includes(key)
            ) {
                newPayload[key] = new Date(newPayload[key]);
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useSessionStore = defineStore('sessions', () => {
    const sessions = ref<Session[]>([]);

    const fetchSessions = async (query: Filters) => {
        const response = await API.get(`client/v2/sessions`, {
            params: query
        });
        if (response?.data) {
            sessions.value = response?.data.map((s: SessionResponse) =>
                mapSession(s)
            );
        }
    };

    const mapSession = (s: SessionResponse): Session => {
        return {
            ...s,
            clientDenomination: s.client.denomination,
            clientSiret: s.client.siret ?? undefined,
            typeShortcut: buildProduitTypeShortcut(s.type.id, s.lienElearning),
            typeLabel: buildProduitTypeLabel(s.type.label, s.lienElearning),
            statutLabel: s.statut.label,
            modeLabel: 'Intra',
            ...(s.convention?.id && {
                conventionStatut: buildDocumentStatut(
                    s.convention?.disponible,
                    s.convention?.signee,
                    s.convention?.validee
                )
            }),
            ...(s.pif?.id && {
                pifStatut: buildDocumentStatut(
                    s.pif?.disponible,
                    s.pif?.signee,
                    s.pif?.validee
                )
            }),
            ...(s.hasCertificats && {
                certificatLabel: 'Télécharger'
            }),
            clientId: s.client.id,
            titre: `${s.produit.titre}${s.familleStageEC ? ' (Stage EC)' : ''}${s.familleStageCAC ? ' (Stage CAC)' : ''}`,
            datesFormations: buildDate(s.dateDebut, s.dateFin),
            titreAlt: s.parcours?.titre
                ? s.parcours.titre
                : s.cursus?.titre
                  ? s.cursus.titre
                  : undefined
        };
    };

    return {
        sessions,
        fetchSessions
    };
});
