<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-sm'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Le nouvel espace de gestion est arrivé !
      </h1>
    </template>
    <template v-slot:modal-body>
      <div class="py-2 text-lg">Il remplace l'ancien espace entreprise.</div>
      <div
        class="cursor-pointer py-2 text-justify text-lg text-jinius-green underline">
        <a
          target="_blank"
          v-bind:href="`${cdn_url}/aide-en-ligne/Plaquette-Espace-Gestion.pdf`">
          Plus d'informations en cliquant ici.
        </a>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="rounded bg-jinius-dark-green px-4 py-3 text-white hover:bg-jinius-green"
          v-on:click.prevent="submit">
          Ne plus afficher ce message
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import { map_domain_per_env } from '@/assets/data/data_maps.js';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose'],
    data: () => ({}),
    computed: {
      cdn_url() {
        return `https://cdn.${map_domain_per_env[import.meta.env.VITE_CONTEXT]}`;
      }
    },
    methods: {
      close() {
        this.onclose();
      },
      submit() {
        localStorage.setItem('nouveau-espace-entreprise-info-date', new Date());
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
