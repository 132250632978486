import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createTextVNode(";"),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.58 3.193a1 1 0 0 1 .84 0l8 3.693a1 1 0 0 1 .58.908V8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.206a1 1 0 0 1 .58-.908zM4 7.852c0 .124.1.225.225.225h15.55a.225.225 0 0 0 .095-.43l-7.45-3.455a1 1 0 0 0-.84 0L4.13 7.647a.23.23 0 0 0-.13.205"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6 10.5a.5.5 0 0 1 .5.5v9.5h-1V11a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v9.5h-1V11a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v9.5h-1V11a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v9.5h-1V11a.5.5 0 0 1 .5-.5",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3.5 20.5A.5.5 0 0 1 4 20h16a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }