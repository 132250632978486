<template>
  <div class="p-[8px]">
    <template v-if="inscriptions.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="inscriptions-1"
        table_index="inscriptions-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="inscriptions">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Inscription } from '@/types/gestion/inscriptions-types.js';
  import { useInscriptionStore } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import { useRouter } from 'vue-router';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { h } from 'vue';
  import InscriptionsTableItemDocuments from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-table-item-documents.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  const props = withDefaults(
    defineProps<{
      fetch?: Function;
    }>(),
    {}
  );
  const router = useRouter();
  const goToDetails = (row: Inscription) => {
    if (row.disabled) {
      return;
    }
    if (
      row.typeShortcut.includes('e-learning') &&
      row.typeShortcut.length == 1
    ) {
      router.push(`/gestion/inscriptions-elearning?id=${row.id}`);
    } else if (row.typeShortcut.includes('cursus')) {
      router.push(`/gestion/inscriptions-cursus?id=${row.id}`);
    } else {
      router.push(`/gestion/inscriptions-session?id=${row.id}`);
    }
  };
  const goToCommandeDetails = (row: Inscription) => {
    return `/gestion/commandes-details?reference=${row.commandeReference}`;
  };

  const columns = [
    {
      content_index: 'participantNomPrenom',
      label: 'Participant',
      content_sub_text: (row: Inscription) =>
        row.dateInscription ? `Inscrit le ${row.dateInscription}` : undefined,
      content_sub_link_text: (row: Inscription) =>
        row.commandeReference ?? undefined,
      content_sub_link_prefix: 'Commande : ',
      content_sub_link_path: goToCommandeDetails,
      min_width: 125,
      width: 180
    },
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      content_sub_text: (row: Inscription) =>
        `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu ?? ''}`,
      content_sub_link_class: '!text-refonte-cursus',
      content_sub_link_prefix: 'Cursus : ',
      min_width: 240,
      width: 360
    },
    {
      content_index: 'datesFormations',
      label: 'Dates de formations',
      content_sub_text: (row: Inscription) =>
        row.dureeMinutes
          ? `Durée : ${dates_helpers.methods.formatMinutesToHours(row.dureeMinutes)}`
          : undefined,
      min_width: 110,
      width: 190
    },
    {
      content_index: 'statutLabel',
      label: 'Statut',
      width: 85,
      content_custom: (row: Inscription) =>
        h('span', {
          innerHTML: row.statutLabel,
          class:
            row.statutLabel === 'Annulé' || row.statutLabel === 'Transféré'
              ? 'text-jinius-red'
              : ''
        })
    },
    {
      content_index: 'organisateur',
      label: 'Organisateur',
      min_width: 100,
      width: 170
    },
    {
      content_index: 'testPositionnementStatut',
      label: 'T. Pos.',
      min_width: 60,
      width: 100,
      tooltip: 'Tests de positionnement'
    },
    {
      content_index: 'id',
      label: 'Documents',
      min_width: 150,
      width: 200,
      content_custom: (row: Inscription) => {
        if (row.disabled) {
          return h('span', '-');
        }
        return h(InscriptionsTableItemDocuments, {
          row,
          refresh_rows: props.fetch ? props.fetch : () => {}
        });
      },
      tooltip: ' ',
      class: (row: Inscription) => {
        if (row.conventionStatut || row.pifStatut || row.certificatLabel) {
          return '!bg-refonte-pale-violet-2 !flex-grow-0';
        }
        return '!flex-grow-0';
      }
    }
  ];

  const inscriptionStore = useInscriptionStore();

  const { inscriptions } = storeToRefs(inscriptionStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
