<template>
  <div
    class="table-item-documents"
    title=" "
    v-on:click="
      (e) => {
        e.stopPropagation();
      }
    ">
    <div v-if="props.row.sessionStatutLabel !== 'Annulé'">
      <div
        v-if="props.row.conventionStatut"
        class="table-item-document"
        v-bind:class="
          props.row.convention?.disponible &&
          !props.row.convention?.signee &&
          !props.row.convention?.validee
            ? 'table-item-document-to-sign'
            : ''
        "
        v-on:click="
          async () => {
            setIsConventionModalOpen(true);
            await fetchConventions(row);
          }
        ">
        <div class="table-item-document-icon">
          <component
            v-bind:is="
              getDocumentIcon(
                props.row.convention?.disponible,
                props.row.convention?.signee,
                props.row.convention?.validee
              )
            " />
        </div>
        <span class="table-item-document-text">
          Convention(s)&nbsp;: {{ props.row.conventionStatut }}
        </span>
      </div>
      <div
        v-if="props.row.pifStatut"
        class="table-item-document"
        v-on:click="
          async () => {
            setIsPIFModalOpen(true);
            await fetchPifs(row);
          }
        ">
        <div class="table-item-document-icon">
          <component
            v-bind:is="
              getDocumentIcon(
                props.row.pif?.disponible,
                props.row.pif?.signee,
                props.row.pif?.validee
              )
            " />
        </div>
        <span class="table-item-document-text">
          PIF(s)&nbsp;: {{ props.row.pifStatut }}
        </span>
      </div>
      <div
        v-if="props.row.certificatLabel"
        class="table-item-document"
        v-on:click="
          async () => {
            setIsCertificatModalOpen(true);
            await fetchCertificats(row);
          }
        ">
        <div class="table-item-document-icon">
          <component
            v-bind:is="props.row.hasCertificats ? IconDownload : undefined" />
        </div>
        <span class="table-item-document-text"> Certificat(s) </span>
      </div>
      <div
        v-if="
          !props.row.conventionStatut &&
          !props.row.pifStatut &&
          !props.row.certificatLabel
        "
        class="table-item-document">
        <span class="table-item-document-text !text-refonte_blue-200"> - </span>
      </div>
    </div>
    <div
      v-else
      class="table-item-document">
      <span class="table-item-document-text !text-refonte_blue-200"> - </span>
    </div>
  </div>
  <refonte-modal-file
    v-bind:show="isConventionModalOpen"
    v-bind:on_close="() => setIsConventionModalOpen(false)"
    title="Convention(s)"
    v-bind:files="conventionsFiles"
    v-bind:build_upload_url_for_file="
      (convention) => getConventionUploadUrl(convention)
    "
    v-bind:file_on_click_upload_url="
      () => {
        fetchConventions(props.row);
        props.refresh_rows();
      }
    "
    v-bind:file_on_click_download="
      (convention) => downloadConvention(props.row.clientId, convention)
    " />
  <refonte-modal-file
    v-bind:show="isPIFModalOpen"
    v-bind:on_close="() => setIsPIFModalOpen(false)"
    title="PIF(s)"
    v-bind:files="pifsFiles"
    v-bind:file_on_click_download="
      (pif) => downloadPif(props.row.clientId, pif)
    "
    v-bind:build_upload_url_for_file="(pif) => getPifUploadUrl(pif)"
    v-bind:file_on_click_upload_url="
      () => {
        fetchPifs(props.row);
        props.refresh_rows();
      }
    "
    file_signed_label="Signé" />
  <refonte-modal-file
    v-bind:show="isCertificatModalOpen"
    v-bind:on_close="() => setIsCertificatModalOpen(false)"
    title="Certificat(s)"
    v-bind:files="certificatsFiles"
    v-bind:file_on_click_download="
      (file) => downloadCertificat(props.row.clientId, file.id)
    "
    v-bind:file_no_signature_needed="true" />
</template>

<script setup lang="ts">
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteModalFile from '@/components/refonte/modals/refonte-modal-file.vue';
  import { ref } from 'vue';
  import { API } from '@/http-common';
  import {
    downloadCertificat,
    downloadConvention,
    downloadPif
  } from '@/mixin/client_download__functions';
  import { Session } from '@/types/gestion/sessions-types';

  const props = withDefaults(
    defineProps<{
      row: Session;
      refresh_rows: Function;
    }>(),
    {}
  );

  const getDocumentIcon = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
  ) => {
    if (disponible && !signee && !validee) {
      return IconUpload;
    }
    if (signee || validee) {
      return IconDownload;
    }
  };

  const isConventionModalOpen = ref<boolean>(false);
  const isPIFModalOpen = ref<boolean>(false);
  const isCertificatModalOpen = ref<boolean>(false);

  function setIsConventionModalOpen(value: boolean) {
    isConventionModalOpen.value = value;
  }
  function setIsPIFModalOpen(value: boolean) {
    isPIFModalOpen.value = value;
  }
  function setIsCertificatModalOpen(value: boolean) {
    isCertificatModalOpen.value = value;
  }

  const conventionsFiles = ref([]);
  const certificatsFiles = ref([]);
  const pifsFiles = ref([]);
  const loading = ref<boolean>(false);

  const fetchCertificats = async (row: Session) => {
    loading.value = true;
    const response = await API.get(
      `client/v2/sessions/session/${row.id}/certificats`
    );
    if (response && response.data) {
      certificatsFiles.value = response.data.map((c) => mapCertificat(c, row));
    }
    loading.value = false;
  };

  const fetchPifs = async (row: Session) => {
    loading.value = true;
    const response = await API.get(`client/v2/sessions/session/${row.id}/pifs`);
    if (response && response.data) {
      pifsFiles.value = response.data.map((c) => mapPif(c, row));
    }
    loading.value = false;
  };

  const fetchConventions = async (row: Session) => {
    loading.value = true;
    const response = await API.get(
      `client/v2/sessions/session/${row.id}/conventions`
    );
    if (response && response.data) {
      conventionsFiles.value = response.data.map((c) => mapConvention(c, row));
    }
    loading.value = false;
  };

  const mapCertificat = (certificat, row) => {
    return {
      ...certificat,
      title: `Certificat ${certificat.nomPrenomParticipant ?? ''}`,
      formation: certificat.produitTitre
        ? certificat.produitTitre
        : row.produit.titre
    };
  };

  const mapConvention = (convention, row) => {
    return {
      ...convention,
      title:
        convention.typeId == 1
          ? `Convention collective ${convention.commandeReference ?? ''}`
          : `Convention individuelle ${convention.nomPrenomParticipant ?? ''}`,
      formation: convention.produitTitre
        ? convention.produitTitre
        : row.produit.titre,
      signed: convention.signee || convention.validee
    };
  };

  const mapPif = (pif, row) => {
    return {
      ...pif,
      title: 'PIF',
      formation: pif.produitTitre ? pif.produitTitre : row.produit.titre,
      signed: pif.signee || pif.validee
    };
  };

  const getConventionUploadUrl = (convention) => {
    return `client/${props.row.clientId}/convention/${convention.id}/file`;
  };

  const getPifUploadUrl = (pif) => {
    return `client/${props.row.clientId}/pif/${pif.id}/file`;
  };
</script>

<style lang="scss" scoped>
  .table-item-documents {
    @apply flex flex-col;
    gap: 6px;

    .table-item-document {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-document-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
      .table-item-document-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
      &:hover {
        .table-item-document-text {
          @apply underline;
        }
      }

      &.table-item-document-to-sign {
        .table-item-document-icon {
          :deep(svg) {
            @apply text-refonte-secondary;
          }
        }
        .table-item-document-text {
          @apply text-refonte-secondary;
        }
      }
    }
  }
</style>
