<template>
  <form
    class="refonte-search-form"
    v-bind:class="props.class">
    <div class="search-form-icon">
      <IconSearch />
    </div>
    <input
      class="search-form-input"
      type="search"
      v-bind:placeholder="props.placeholder"
      v-on:keydown.enter.prevent
      v-on:keyup.enter.prevent
      v-on:input="(e) => props.on_search(e.target.value)" />
  </form>
</template>

<script setup lang="ts">
  import IconSearch from '@/assets/img/refonte/icons/action/icon-search.svg?component';

  const props = withDefaults(
    defineProps<{
      placeholder?: string;
      on_search?: Function;
      class?: string;
    }>(),
    {
      placeholder: 'Rechercher...',
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-search-form {
    @apply flex items-center self-stretch;
    padding: 0 4px;
    gap: 6px;

    .search-form-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 16px;
      height: 16px;

      :deep(svg) {
        @apply text-refonte_blue-600;
        width: 16px;
        height: 16px;
      }
    }
    .search-form-input {
      @apply w-full flex-grow text-refonte_blue-600;
      padding: 7px 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;

      &::placeholder {
        @apply text-refonte_blue-600;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
</style>
