import ErrorNotFound from '@/views/errors/error-404.vue';
import ErrorAuthentification from '@/views/errors/error-authentification.vue';
import ErrorServer from '@/views/errors/error-server.vue';
import AuthViewRedirector from '@/views/redirector.vue';
import Espace from '@/views/_espace/index.vue';
import AideEnLigne from '@/views/_espace/_aide/index.vue';
import DesignSystem from '@/views/_espace/_refonte/design-system/refonte-design-system.vue';
import RefonteEspace from '@/views/_espace/_refonte/refonte-index.vue';
import Rgpd from '@/views/_espace/_rgpd/index.vue';

const navigation_tree = [
    {
        path: '/rgpd',
        name: 'rgpd',
        component: Rgpd,
        meta: {
            label: 'JINIUS - Politique RGPD / Mentions légales',
            no_container_wrapper: true
        }
    },
    {
        /*
            Becareful of precedence in route's hierachy so that for example "aide en ligne" doesn't trigger the route => /:space_id
            By doing it prevents forcing a not desired requiredAuth matching
        */
        path: '/aide-en-ligne',
        name: 'aide-en-ligne',
        component: AideEnLigne,
        meta: {
            label: 'JINIUS - Aide en ligne',
            no_container_wrapper: true
        }
    },
    {
        path: '/design-system',
        name: 'design-system',
        meta: {
            label: 'Design system',
            no_container_wrapper: true,
            requiresAuth: true
        },
        component: DesignSystem
    },
    {
        path: '/gestion',
        redirect: '/gestion/accueil'
    },
    {
        path: '/gestion/:sub_space_id',
        name: 'refonte-espace-sub',
        props: true,
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true,
            refonte: true
        },
        component: RefonteEspace
    },
    {
        path: '/gestion/:sub_space_id/:sub_children_id',
        name: 'refonte-espace-sub-children',
        props: true,
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true,
            refonte: true
        },
        component: RefonteEspace
    },
    {
        path: '/',
        name: 'espace',
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true
        },
        component: Espace
    },
    {
        path: '/:space_id',
        name: 'espace-entry',
        props: true,
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true
        },
        component: Espace
    },
    {
        path: '/:space_id/:sub_space_id',
        name: 'espace-sub',
        props: true,
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true
        },
        component: Espace
    },
    {
        path: '/:space_id/:sub_space_id/:sub_children_id',
        name: 'espace-sub-children',
        props: true,
        meta: {
            icon: 'sync',
            label: 'Mes espaces en ligne',
            no_container_wrapper: true,
            requiresAuth: true
        },
        component: Espace
    },
    {
        path: '/connexion',
        name: 'connexion',
        meta: {
            label: 'Connexion',
            message:
                'Veuillez vous authentifier pour accéder à votre espace personnel'
        },
        beforeEnter(to, from, next) {
            location.href = `${import.meta.env.VITE_API_AUTH}/login?to=${import.meta.env.VITE_AUTH_REDIRECT}`;
            next();
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error-404',
        component: ErrorNotFound
    },
    {
        path: '/error-server',
        name: 'error-server',
        props: true,
        component: ErrorServer
    },
    {
        path: '/error-authentification',
        name: 'error-authentification',
        props: true,
        meta: { no_account_button: true },
        component: ErrorAuthentification
    },
    {
        path: '/redirector',
        name: 'redirector',
        meta: { no_container_wrapper: true },
        component: AuthViewRedirector
    }
];

export { navigation_tree };
