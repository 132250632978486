import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const initialAborescence = {
    gestion: {
        id: 'gestion',
        label: 'Gestion',
        rubriques: {
            gestion: {
                id: 'gestion',
                label: 'Gestion & Suivi',
                reduced_label: 'Gestion',
                sous_rubriques: {
                    accueil: {
                        id: 'accueil',
                        label: 'Tableau de bord',
                        icon: 'IconDashboard'
                    },
                    inscriptions: {
                        id: 'inscriptions',
                        label: 'Inscriptions',
                        icon: 'IconFormations'
                    },
                    sessions: {
                        id: 'sessions',
                        label: 'Sessions intra',
                        icon: 'IconFlagParcours'
                    },
                    commandes: {
                        id: 'commandes',
                        label: 'Commandes',
                        icon: 'IconCommandes'
                    },
                    factures: {
                        id: 'factures',
                        label: 'Factures',
                        icon: 'IconFactures'
                    },
                    contrats: {
                        id: 'contrats',
                        label: 'Contrats Form.',
                        headerLabel: 'Contrats Formateurs',
                        icon: 'IconTestPositionnement',
                        menuHidden: true
                    },
                    'inscriptions-session': {
                        id: 'inscriptions-session',
                        label: 'Inscriptions',
                        icon: 'IconFormations',
                        hideScope: true,
                        menuHidden: true
                    },
                    'inscriptions-cursus': {
                        id: 'inscriptions-cursus',
                        label: 'Inscriptions',
                        icon: 'IconFormations',
                        hideScope: true,
                        menuHidden: true
                    },
                    'inscriptions-elearning': {
                        id: 'inscriptions-elearning',
                        label: 'Inscriptions',
                        icon: 'IconFormations',
                        hideScope: true,
                        menuHidden: true
                    },
                    'sessions-details': {
                        id: 'sessions-details',
                        label: 'Sessions intra',
                        icon: 'IconFlagParcours',
                        hideScope: true,
                        menuHidden: true
                    },
                    'commandes-details': {
                        id: 'commandes-details',
                        label: 'Commandes',
                        icon: 'IconCommandes',
                        hideScope: true,
                        menuHidden: true
                    }
                }
            },
            administration: {
                id: 'administration',
                label: 'Administration',
                reduced_label: 'Admin',
                need_admin_rights: true,
                sous_rubriques: {
                    annuaire: {
                        id: 'annuaire',
                        label: 'Annuaire',
                        icon: 'IconParticipants',
                        admin: true
                    },
                    interlocuteurs: {
                        id: 'interlocuteurs',
                        label: 'Interlocuteurs',
                        icon: 'IconInterlocuteurs',
                        admin: true
                    },
                    etablissements: {
                        id: 'etablissements',
                        label: 'Etablissements',
                        icon: 'IconEstablishments',
                        admin: true
                    },
                    'etablissements-details': {
                        id: 'etablissements-details',
                        label: 'Etablissements',
                        icon: 'IconEstablishments',
                        hideScope: true,
                        menuHidden: true,
                        admin: true
                    },
                    satisfaction: {
                        id: 'satisfaction',
                        label: 'Enquêtes client',
                        icon: 'IconTestValidation',
                        admin: true
                    }
                }
            }
        },
        connexionPriority: 3,
        restricted: true,
        color: 'jinius-blue'
    }
};
export const useArborescenceStore = defineStore('arborescence', () => {
    const arborescence = ref(initialAborescence);

    const route = useRoute();

    const activeSpaceId = ref(route.params.space_id ?? 'gestion');
    const activeSubSpaceId = ref(route.params.sub_space_id);

    const subLabel = ref();

    const getActiveSousRubrique = (subSpaceId) => {
        if (subSpaceId) {
            const rubriques =
                activeSpaceId.value &&
                Object.keys(arborescence.value[activeSpaceId.value].rubriques)
                    .map(
                        (r) =>
                            arborescence.value[activeSpaceId.value].rubriques[r]
                                .sous_rubriques
                    )
                    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
            return rubriques[subSpaceId];
        }
        return null;
    };
    const activeRubrique = ref(getActiveSousRubrique(activeSubSpaceId.value));

    watch(
        () => activeSpaceId,
        (newId) => {
            activeRubrique.value = getActiveSousRubrique(newId.value);
        }
    );
    watch(
        () => activeSubSpaceId,
        (newId) => {
            activeRubrique.value = getActiveSousRubrique(newId.value);
        }
    );

    function setActiveSpaceId(id) {
        activeSpaceId.value = id;
    }
    function setActiveSubSpaceId(id) {
        activeSubSpaceId.value = id;
        activeRubrique.value = getActiveSousRubrique(id);
    }
    const setSubLabel = (value) => {
        subLabel.value = value;
    };

    const setSousRubrique = (espace, rubrique, sousRubrique, name, value) => {
        const arboCopy = arborescence.value;
        arboCopy[espace].rubriques[rubrique].sous_rubriques[sousRubrique] = {
            ...arboCopy[espace].rubriques[rubrique].sous_rubriques[
                sousRubrique
            ],
            [name]: value
        };
        arborescence.value = arboCopy;
    };

    return {
        arborescence,
        activeRubrique,
        setActiveSubSpaceId,
        setActiveSpaceId,
        subLabel,
        setSubLabel,
        setSousRubrique
    };
});
