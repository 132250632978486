<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    title="Modifier un établissement"
    class="!max-w-[800px]">
    <template v-slot:modalBody>
      <form v-on:submit.prevent="submit">
        <div class="flex flex-col gap-[32px]">
          <div>
            <div class="modal-modifier-etablissement-categorie">
              <span>Informations générales</span>
            </div>
            <div v-if="nature">
              <refonte-label label="Nature" />
              <p class="pb-[16px] text-refonte-black">
                {{ nature.label }}
              </p>
            </div>
            <div v-if="etablissementForm.reference">
              <refonte-label label="Référence" />
              <p class="pb-[16px] text-refonte-black">
                {{ etablissementForm.reference }}
              </p>
            </div>
            <refonte-input
              v-if="nature && nature.shortcut === 'siret'"
              v-bind:placeholder="
                etablissementForm.valideSansSiret ? 'Équivalent SIRET' : 'SIRET'
              "
              input_class="w-full"
              v-bind:value="etablissementForm.siret"
              v-on:input="setEtablissmentForm($event, 'siret')"
              v-bind:disabled="!etablissementForm.valideSansSiret" />
            <refonte-input
              v-if="nature && nature.shortcut === 'rna'"
              placeholder="RNA"
              input_class="w-full"
              v-bind:value="etablissementForm.rna"
              v-on:input="setEtablissmentForm($event, 'rna')" />
            <refonte-input
              placeholder="Dénomination"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.denomination"
              v-on:input="setEtablissmentForm($event, 'denomination')"
              v-bind:required="true"
              v-bind:error="v$.etablissementForm.denomination.$error" />
            <refonte-input
              placeholder="Numéro de TVA Intra"
              input_class="w-full"
              v-bind:value="etablissementForm.numeroTVAIntra"
              v-on:input="setEtablissmentForm($event, 'numeroTVAIntra')" />
            <template v-if="nature && nature.shortcut === 'siret'">
              <div
                class="flex w-full items-center gap-[8px] pb-[16px] text-refonte-black">
                <div class="flex items-center gap-[4px]">
                  <label> Établissement siège </label>
                </div>
                <div class="flex items-center gap-[8px]">
                  <refonte-radio
                    name="etablissementSiege"
                    v-bind:value="true"
                    label="Oui"
                    v-bind:checked="etablissementForm.etablissementSiege"
                    v-on:input="
                      setEtablissmentForm($event, 'etablissementSiege')
                    "
                    class="!min-w-0" />
                  <refonte-radio
                    name="etablissementSiege"
                    v-bind:value="false"
                    label="Non"
                    v-bind:checked="etablissementForm.etablissementSiege"
                    v-on:input="
                      setEtablissmentForm($event, 'etablissementSiege')
                    "
                    class="!min-w-0" />
                </div>
              </div>
              <div class="flex w-full items-center gap-[8px] pb-[16px]">
                <refonte-select
                  placeholder="Tranche d'effectif"
                  class="flex-grow"
                  input_class="w-full"
                  v-bind:options="trancheEffectifs"
                  value_label="label"
                  v-bind:value="
                    trancheEffectifs.find(
                      (trancheEffectif) =>
                        etablissementForm.trancheEffectifs ===
                        trancheEffectif.id
                    )
                  "
                  v-on:input="
                    setEtablissmentForm($event.id, 'trancheEffectifs')
                  " />
                <div
                  v-tooltip="{
                    content: `<ul>
                      <li>Unités non employeuses: pas de salarié au cours de l'année de référence et pas d'effectif au 31/12</li>
                      <li>0 salarié : n'ayant pas d'effectif au 31/12 mais ayant employé des salariés au cours de l'année de référence</li>
                    </ul>`
                  }">
                  <font-awesome-icon
                    icon="info-circle"
                    class="text-refonte_blue-600" />
                </div>
              </div>
              <div class="flex w-full items-center gap-[8px] pb-[16px]">
                <refonte-input
                  placeholder="Activité"
                  class="!pb-0"
                  input_class="w-full"
                  v-bind:maxlength="6"
                  v-bind:value="etablissementForm.activite"
                  v-on:input="setEtablissmentForm($event, 'activite')" />
                <div
                  v-if="etablissementForm.activite"
                  v-bind:class="`flex-grow rounded bg-core-200 px-[16px] pb-[6px] pt-[8px] text-center text-xs font-bold uppercase italic leading-none ${activites[etablissementForm.activite] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                  {{
                    activites[etablissementForm.activite]
                      ? activites[etablissementForm.activite]
                      : 'Code inexistant dans la nomenclature'
                  }}
                </div>
              </div>
              <div class="flex w-full items-center gap-[8px] pb-[16px]">
                <refonte-input
                  placeholder="Catégorie juridique"
                  class="!pb-0"
                  input_class="w-full"
                  v-bind:maxlength="4"
                  v-bind:value="etablissementForm.categorieJuridique"
                  v-on:input="
                    setEtablissmentForm($event, 'categorieJuridique')
                  " />
                <div
                  v-if="etablissementForm.categorieJuridique"
                  v-bind:class="`flex-grow rounded bg-core-200 px-[16px] pb-[6px] pt-[8px] text-center text-xs font-bold uppercase italic leading-none ${categories_juridiques[etablissementForm.categorieJuridique] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                  {{
                    categories_juridiques[etablissementForm.categorieJuridique]
                      ? categories_juridiques[
                          etablissementForm.categorieJuridique
                        ]
                      : 'Catégorie inexistante'
                  }}
                </div>
              </div>
            </template>
            <refonte-select
              placeholder="Territorialité"
              input_class="w-full"
              v-bind:options="territorialites"
              value_label="label"
              v-bind:value="
                territorialites.find(
                  (territorialite) =>
                    etablissementForm.territorialiteId === territorialite.id
                )
              "
              v-on:input="setEtablissmentForm($event.id, 'territorialiteId')"
              v-bind:disabled="true" />
          </div>
          <div>
            <div class="modal-modifier-etablissement-categorie">
              <span>Adresse</span>
            </div>
            <refonte-input
              placeholder="Identification"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.identification"
              v-on:input="setEtablissmentForm($event, 'identification')" />
            <refonte-input
              placeholder="Dest./Service"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.destService"
              v-on:input="setEtablissmentForm($event, 'destService')" />
            <refonte-input
              placeholder="Complément d'adresse"
              input_class="w-full"
              v-bind:maxlength="38"
              v-bind:value="etablissementForm.complement"
              v-on:input="setEtablissmentForm($event, 'complement')" />
            <refonte-input
              placeholder="N° et voie"
              input_class="w-full"
              v-bind:maxlength="38"
              v-bind:value="etablissementForm.numeroVoie"
              v-on:input="setEtablissmentForm($event, 'numeroVoie')"
              v-bind:required="true"
              v-bind:error="v$.etablissementForm.numeroVoie.$error" />
            <refonte-input
              placeholder="Mention spéciale"
              input_class="w-full"
              v-bind:maxlength="38"
              v-bind:value="etablissementForm.mentionSpeciale"
              v-on:input="setEtablissmentForm($event, 'mentionSpeciale')" />
            <refonte-input
              placeholder="Ville"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.ville"
              v-on:input="setEtablissmentForm($event, 'ville')"
              v-bind:required="true"
              v-bind:error="v$.etablissementForm.ville.$error" />
            <refonte-input
              placeholder="Code postal"
              input_class="w-full"
              v-bind:maxlength="5"
              format="numeric"
              v-bind:value="etablissementForm.codePostal"
              v-on:input="setEtablissmentForm($event, 'codePostal')"
              v-bind:required="true"
              v-bind:error="v$.etablissementForm.codePostal.$error" />
            <refonte-select
              placeholder="Pays"
              input_class="w-full"
              v-bind:options="pays"
              value_label="label"
              v-bind:value="
                pays.find((item) => etablissementForm.pays === item.id)
              "
              v-on:input="setEtablissmentForm($event.id, 'pays')" />
          </div>
          <div>
            <div class="modal-modifier-etablissement-categorie">
              <span>Informations d'inscription</span>
            </div>
            <refonte-select
              placeholder="Qui a le droit de passer commande au nom de la société ?"
              input_class="w-full"
              v-bind:options="droitsCommande"
              value_label="label"
              v-bind:value="
                droitsCommande.find(
                  (item) => etablissementForm.commandeProfilId === item.id
                )
              "
              v-on:input="setEtablissmentForm($event.id, 'commandeProfilId')"
              v-bind:required="true"
              v-bind:error="v$.etablissementForm.commandeProfilId.$error" />
          </div>
          <div>
            <div class="modal-modifier-etablissement-categorie">
              <span>Informations de facturation</span>
            </div>
            <div
              class="flex w-full items-center justify-end gap-[8px] text-refonte-black">
              <div class="flex flex-grow items-center gap-[4px]">
                <label>
                  Autoriser d'autres tiers à choisir votre société en tant
                  qu'entité de facturation ?
                </label>
              </div>
              <div class="flex items-center gap-[8px]">
                <refonte-radio
                  name="entiteFacturation"
                  v-bind:value="true"
                  label="Oui"
                  v-bind:checked="etablissementForm.entiteFacturation"
                  v-on:input="
                    (e: any) => {
                      setEtablissmentForm(e, 'entiteFacturation');
                      if (e) {
                        setEtablissmentForm(true, 'entiteFacturationPourTous');
                      }
                    }
                  "
                  class="!min-w-0" />
                <refonte-radio
                  name="entiteFacturation"
                  v-bind:value="false"
                  label="Non"
                  v-bind:checked="etablissementForm.entiteFacturation"
                  v-on:input="
                    (e: any) => {
                      setEtablissmentForm(e, 'entiteFacturation');
                      if (e) {
                        setEtablissmentForm(true, 'entiteFacturationPourTous');
                      }
                    }
                  "
                  class="!min-w-0" />
              </div>
            </div>
            <div
              v-if="etablissementForm.entiteFacturation"
              class="flex w-full items-center justify-end gap-[8px] pt-[16px] text-refonte-black">
              <div class="flex flex-grow items-center gap-[4px]">
                <label> Tiers à autoriser </label>
              </div>
              <div class="flex items-center gap-[8px]">
                <refonte-radio
                  name="entiteFacturationPourTous"
                  v-bind:value="true"
                  label="Tous"
                  v-bind:checked="etablissementForm.entiteFacturationPourTous"
                  v-on:input="
                    setEtablissmentForm($event, 'entiteFacturationPourTous')
                  "
                  class="!min-w-0" />
                <refonte-radio
                  name="entiteFacturationPourTous"
                  v-bind:value="false"
                  label="Uniquement les tiers suivants"
                  v-bind:checked="etablissementForm.entiteFacturationPourTous"
                  v-on:input="
                    setEtablissmentForm($event, 'entiteFacturationPourTous')
                  "
                  class="!min-w-0" />
              </div>
            </div>
            <div
              v-if="
                etablissementForm.entiteFacturation &&
                !etablissementForm.entiteFacturationPourTous &&
                !isEntiteFacturationLoading
              "
              class="pt-[16px]">
              <refonte-button
                size="sm"
                class="mb-[8px]"
                v-bind:on_click="() => setIsClientModalOpen(true)">
                Ajouter une entité
              </refonte-button>
              <client-modal
                title="Rechercher une entité à facturer"
                v-bind:on_client_form_submit="onClientFormSubmit"
                v-bind:onclose="() => setIsClientModalOpen(false)"
                v-bind:modal_show="isClientModalOpen"
                v-bind:hide_particulier="true" />
              <refonte-table
                key="entites-1"
                table_index="entites-1"
                data_etablissement="denomination"
                data_etablissement_siret="siret"
                v-bind:data_etablissement_status="
                  (row: Etablissement) =>
                    row.desactivable ? undefined : 'Désactivé'
                "
                v-bind:data_etablissement_pastille="
                  (row: Etablissement) =>
                    row.desactivable ? 'active' : 'deactivated'
                "
                v-bind:data_etablissement_width="350"
                v-bind:columns="columns"
                v-bind:data="facturation_etablissements"
                class="modal-modifier-etablissement-table" />
            </div>
          </div>
          <div>
            <div class="modal-modifier-etablissement-categorie">
              <span>Contact</span>
            </div>
            <refonte-input
              placeholder="Téléphone"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.telephone"
              v-on:input="setEtablissmentForm($event, 'telephone')" />
            <refonte-input
              placeholder="E-mail"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.email"
              v-on:input="setEtablissmentForm($event, 'email')"
              v-bind:error="v$.etablissementForm.email.$error"
              error_message="Veuillez respecter le format email test@example.com" />
            <refonte-input
              placeholder="Site internet"
              input_class="w-full"
              v-bind:maxlength="100"
              v-bind:value="etablissementForm.siteInternet"
              v-on:input="setEtablissmentForm($event, 'siteInternet')" />
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler
      </refonte-button>
      <refonte-button v-bind:on_click="submit"> Modifier </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import { computed, ref } from 'vue';
  import RefonteSelect from '@/components/refonte/inputs/refonte-select.vue';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import { email, helpers, maxLength, required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import {
    droitsCommande,
    natureList,
    pays,
    territorialites,
    trancheEffectifs
  } from '@/assets/data/data_form';
  import RefonteRadio from '@/components/refonte/inputs/refonte-radio.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import {
    Etablissement,
    EtablissementFacturation,
    EtablissementInformations
  } from '@/types/gestion/etablissements-types';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import ClientModal from '@/views/_espace/_account/_informations/_modal/client-modal.vue';
  import { API } from '@/http-common';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      active_etablissement: EtablissementInformations;
      facturation_etablissements?: EtablissementFacturation[];
      activites: { [key: number]: string }[];
      categories_juridiques: { [key: number]: string }[];
      class?: string;
      fetch_facturation: Function;
    }>(),
    {
      class: ''
    }
  );

  const etablissementForm = ref<EtablissementInformations>({
    ...props.active_etablissement
  });

  const rules = computed(() => ({
    etablissementForm: {
      denomination: {
        maxLength: maxLength(100),
        required
      },
      identification: { maxLength: maxLength(100) },
      email: { email },
      numeroVoie: { required },
      ville: { required },
      codePostal: {
        required,
        maxLength: helpers.ref?.pays ? maxLength(25) : maxLength(5)
      },
      commandeProfilId: { required }
    }
  }));

  const v$ = useVuelidate(rules, { etablissementForm });

  const nature = computed(() => {
    if (etablissementForm.value && etablissementForm.value.natureId) {
      return natureList.find((n) => n.id === etablissementForm.value.natureId);
    }
    return undefined;
  });

  function close() {
    props.on_close();
    etablissementForm.value = {};
    v$.value.$reset();
  }
  function setEtablissmentForm(value: string | number | boolean, name: any) {
    etablissementForm.value[name] = value;
  }
  function submit(e: Event) {
    e.preventDefault();
    v$.value.$touch();
    if (!v$.value.$invalid) {
      props.on_submit && props.on_submit(etablissementForm.value);
      close();
    }
  }
  const onClientFormSubmit = async (data) => {
    if (data && data.id) {
      await addEntiteFacturation(data.id);
    }
  };

  const isEntiteFacturationLoading = ref<boolean>(false);

  const addEntiteFacturation = async (entiteId: number) => {
    const { id } = etablissementForm.value;
    const response = await API.post(
      `client/${id}/etablissements/${entiteId}`,
      {}
    );
    if (response.status == 200 && props.fetch_facturation) {
      props.fetch_facturation(id);
    }
  };

  const deleteEntiteFacturation = async (entiteId: number) => {
    const { id } = etablissementForm.value;
    const response = await API.delete(
      `client/${id}/etablissements/${entiteId}`,
      {}
    );
    if (response.status == 200 && props.fetch_facturation) {
      props.fetch_facturation(id);
    }
  };

  const columns = [
    {
      content_index: 'adresse',
      label: 'Adresse',
      width: 240
    },
    {
      content_index: 'id',
      content_button_icon: (row: Etablissement) => IconClose,
      content_button_on_click: (row: Etablissement) => {
        deleteEntiteFacturation(row.id);
      },
      content_button_variant: (row: Etablissement) => 'danger',
      content_button_title: (row: Etablissement) => 'Supprimer',
      width: 40
    }
  ];

  const isClientModalOpen = ref<boolean>(false);

  function setIsClientModalOpen(value: boolean) {
    isClientModalOpen.value = value;
  }
</script>

<style lang="scss" scoped>
  .modal-modifier-etablissement-categorie {
    @apply bg-refonte_blue-100;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;

    span {
      @apply text-refonte_blue-600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .modal-modifier-etablissement-table {
    :deep(.refonte-table-row) {
      @apply bg-refonte-pale-violet-2;
    }
  }
</style>
