<template>
  <refonte-filters
    v-if="metadataLoaded"
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.statutId) ||
      filters_utils.methods.hasValue(filters.formateurId) ||
      filters_utils.methods.hasValue(filters.natures)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:number="filters.statutId !== undefined ? 1 : undefined">
        Statut
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="statutsList.length"
            v-for="statut in statutsList"
            v-bind:on_click="
              () =>
                updateFilter(
                  CONTRATS_FILTERS_CONFIGURATION.statutId.name,
                  statut.id
                )
            "
            v-bind:key="statut.id">
            {{ statut.label }}
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:number="filters.formateurId !== undefined ? 1 : undefined">
        Formateur
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="formateursList.length"
            v-for="formateur in formateursList"
            v-bind:on_click="
              () =>
                updateFilter(
                  CONTRATS_FILTERS_CONFIGURATION.formateurId.name,
                  formateur.id
                )
            "
            v-bind:key="formateur.id">
            {{ formateur.nom }} {{ formateur.prenom }}
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_open="() => resetNatures()"
        v-bind:footer_button_on_click="() => updateNaturesFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryNatures &&
            (temporaryNatures.length !==
              (filters.natures ? filters.natures.length : 0) ||
              temporaryNatures.sort().join(', ') !==
                filters.natures?.sort().join(', '))
          )
        "
        v-bind:number="filters.natures?.length ?? undefined">
        Nature
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="naturesList.length"
            v-for="nature in naturesList"
            v-bind:key="nature.id"
            v-bind:label="nature.label"
            v-bind:value="!!temporaryNatures?.includes(nature.id)"
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateNatures(nature.id)" />
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import {
    CONTRATS_FILTERS_CONFIGURATION,
    useContratFilterStore
  } from '@/stores/contrats';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { API } from '@/http-common';
  import filters_utils from '@/mixin/utils/filters_utils';
  import {
    ContratMetadata,
    ContratMetadataFormateur,
    ContratMetadataLabel
  } from '@/types/gestion/contrats-filters-types';

  const getStatutLabel = (id: number | undefined) => {
    const item =
      statutFilterList.value &&
      statutFilterList.value.length &&
      statutFilterList.value.find((s) => s.id == id);
    return item ? filters_utils.methods.formatFilter('Statut', item.label) : '';
  };

  const getFormateurNomPrenom = (id: number | undefined) => {
    const item =
      formateurFilterList.value &&
      formateurFilterList.value.length &&
      formateurFilterList.value.find((f) => f.id == id);
    return item
      ? filters_utils.methods.formatFilter(
          'Formateur',
          `${item.nom} ${item.prenom}`
        )
      : '';
  };

  const getNaturesLabel = (ids: number[] | undefined) => {
    return ids
      ? filters_utils.methods.formatFilter(
          `Nature${ids.length > 1 ? 's' : ''}`,
          ids
            .map(
              (id) =>
                natureFilterList.value &&
                natureFilterList.value.length &&
                natureFilterList.value.find((type) => type.id === id)?.label
            )
            .join(', ')
        )
      : undefined;
  };

  const contratFilterStore = useContratFilterStore();
  const { filters } = storeToRefs(contratFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[] | number[]
  ) => {
    contratFilterStore.setFilter(name, value);
  };

  const resetFilters = () => {
    contratFilterStore.resetFilters();
    temporaryNatures.value = undefined;
  };

  const temporaryNatures = ref<number[] | undefined>(filters.value.natures);

  const updateNaturesFilter = () => {
    updateFilter(
      CONTRATS_FILTERS_CONFIGURATION.natures.name,
      temporaryNatures.value && temporaryNatures.value.length > 0
        ? temporaryNatures.value
        : undefined
    );
  };

  const resetNatures = () => {
    temporaryNatures.value = filters.value.natures;
  };

  const updateNatures = (value: number) => {
    if (temporaryNatures.value?.find((n) => n === value)) {
      temporaryNatures.value = temporaryNatures.value.filter(
        (n) => n !== value
      );
    } else {
      temporaryNatures.value = temporaryNatures.value
        ? [...temporaryNatures.value, value]
        : [value];
    }
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: CONTRATS_FILTERS_CONFIGURATION.statutId.name,
      value: getStatutLabel(filters.value.statutId)
    },
    {
      id: CONTRATS_FILTERS_CONFIGURATION.formateurId.name,
      value: getFormateurNomPrenom(filters.value.formateurId)
    },
    {
      id: CONTRATS_FILTERS_CONFIGURATION.natures.name,
      value: getNaturesLabel(filters.value.natures)
    }
  ]);

  const statutFilterList = ref<ContratMetadataLabel[]>([]);

  const statutsList = computed(() => statutFilterList.value);

  const formateurFilterList = ref<ContratMetadataFormateur[]>([]);

  const formateursList = computed(() => formateurFilterList.value);

  const natureFilterList = ref<ContratMetadataLabel[]>([]);

  const naturesList = computed(() => natureFilterList.value);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const metadataLoaded = ref<boolean>(false);

  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/contrats/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    );

    if (response && response.data) {
      const data: ContratMetadata = response.data;
      statutFilterList.value = data.statuts ?? [];
      formateurFilterList.value = data.formateurs ?? [];
      natureFilterList.value = data.natures ?? [];
    }
    metadataLoaded.value = true;
  };

  watch(activeClientIds, () => {
    resetFilters();
    fetchMetadata();
  });

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
