<template>
  <div
    class="refonte-table-row"
    v-bind:class="`${props.disabled ? 'disabled' : ''} ${props.table_small ? 'table-row-small' : ''} ${props.on_details ? 'table-row-with-details' : ''} ${props.on_action_click ? 'table-row-with-action' : ''} ${props.selectable && props.row_selected ? 'table-row-checked' : ''} ${props.class}`"
    v-on:click="props.on_details"
    v-bind:title="props.on_details ? 'Cliquez pour voir le détail' : undefined">
    <refonte-checkbox
      v-if="props.selectable"
      v-bind:value="props.row_selected"
      v-on:input="toggleIsRowChecked"
      title=" "
      v-on:click="
        (e) => {
          e.stopPropagation();
        }
      " />
    <div
      v-if="props.on_action_click"
      class="refonte-details-button-container">
      <refonte-button
        size="md"
        class="refonte-details-button"
        v-bind:class="
          props.on_action_label ? 'refonte-details-button-min-width' : ''
        "
        v-on:click="props.on_action_click"
        v-bind:disabled="
          props.on_action_disabled ? props.on_action_disabled(props.row) : false
        "
        v-bind:variant="
          props.on_action_variant ? props.on_action_variant : 'primary'
        ">
        {{
          props.on_action_label ? props.on_action_label(props.row) : 'Détails'
        }}
      </refonte-button>
    </div>

    <div class="table-row-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';

  const props = withDefaults(
    defineProps<{
      selectable?: boolean;
      table_small?: boolean;
      disabled?: boolean;
      row: Object;
      on_details?: Function;
      on_action_click?: Function;
      on_action_disabled?: (row: Object) => boolean;
      on_action_variant?: string;
      on_action_label?: (row: Object) => string;
      class?: string;
      row_selected?: boolean;
    }>(),
    {
      selectable: false,
      table_small: false,
      class: '',
      row_selected: false
    }
  );

  const emit = defineEmits(['select']);
  function toggleIsRowChecked(newValue: boolean) {
    emit('select', newValue);
  }
</script>

<style lang="scss" scoped>
  .disabled {
    @apply pointer-events-none cursor-none opacity-50;
  }
  .refonte-table-row {
    @apply relative flex items-start bg-transparent;
    gap: 8px;
    border-radius: 8px;
    padding: 6px 12px;
    min-width: 100%;

    .refonte-details-button-container {
      @apply inline-flex flex-col items-start;
      gap: 2px;
      padding: 9px 8px;

      .refonte-details-button.refonte-details-button-min-width {
        min-width: 95px !important;
        justify-content: center;
      }
    }

    :deep(.refonte-checkbox) {
      height: 56px;
      padding: 6px;

      .checkbox-background {
        @apply hidden;
      }
      .checkbox-checkmark {
        top: 22px;
        left: 0;
      }
    }

    .table-row-content {
      @apply flex flex-grow flex-wrap items-start justify-end;
      gap: 0;
    }

    &.table-row-checked {
      @apply border-2 border-refonte_blue-600;
      padding: 4px 10px;
    }
    &.table-row-small {
      max-height: 54px;

      :deep(.refonte-checkbox) {
        height: 42px;
        padding: 6px;

        .checkbox-background {
          @apply hidden;
        }
        .checkbox-checkmark {
          top: 15px;
          left: 0;
        }
      }

      .table-row-content {
        :deep(.refonte-table-main-item),
        :deep(.refonte-table-item) {
          padding: 0 4px;
        }
      }
    }
    &.table-row-with-action {
      @media (max-width: 1023.98px) {
        @apply flex-wrap;
      }
      .table-row-content {
        @apply flex flex-grow items-start justify-between;
      }
      .table-row-details {
        @apply flex shrink-0 cursor-pointer items-center justify-center;
        width: 16px;
        height: 56px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
          height: 16px;
        }
      }

      &.table-row-small {
        .table-row-details {
          @apply flex shrink-0 cursor-pointer items-center justify-center;
          width: 16px;
          height: 42px;

          :deep(svg) {
            @apply text-refonte_blue-600;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    &.table-row-with-details {
      @apply cursor-pointer;

      &:hover:not(.disabled) {
        @apply bg-refonte-pale-violet-3;
      }
    }

    &:not(:first-child) {
      @apply relative;

      &:before {
        @apply absolute h-0 border-t-2 border-dashed border-refonte_blue-300;
        content: '';
        top: -2px;
        left: 2px;
        right: 2px;
        opacity: 0.3;
      }
      &.table-row-checked {
        &:before {
          top: -4px;
          left: 0;
          right: 0;
        }
      }
    }
  }
</style>
