<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-if="metadataLoaded"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.references) ||
      filters_utils.methods.hasValue(filters.date) ||
      filters_utils.methods.hasValue(filters.datePeriodeFin) ||
      filters_utils.methods.hasValue(filters.datePeriodeDebut) ||
      filters_utils.methods.hasValue(filters.datePeriodeId) ||
      filters_utils.methods.hasValue(filters.vendeurId) ||
      filters_utils.methods.hasValue(filters.commandeReglee) ||
      filters_utils.methods.hasValue(filters.contactId) ||
      filters_utils.methods.hasValue(filters.formationId) ||
      filters_utils.methods.hasValue(filters.dateFormation) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeFin) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeDebut) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeId) ||
      filters_utils.methods.hasValue(filters.nbResultats)
    ">
    <template v-slot:default>
      <div class="filters-group">
        <refonte-filters-item
          v-bind:on_search="(value: string) => updateReferenceValue(value)"
          v-bind:on_open="() => resetReferences()"
          v-bind:footer_button_on_click="() => updateReferencesFilter()"
          v-bind:footer_button_disabled="
            !(
              temporaryReferences &&
              (temporaryReferences.length !==
                (filters.references ? filters.references.length : 0) ||
                temporaryReferences.sort().join(', ') !==
                  filters.references?.sort().join(', '))
            )
          "
          v-bind:number="filters.references?.length ?? undefined">
          Référence
          <template v-slot:dropdownContent>
            <refonte-checkbox
              v-if="referencesList.length"
              v-for="r in referencesList"
              v-bind:key="r.reference"
              v-bind:label="r.reference"
              v-bind:sub_label="r.date"
              v-bind:value="!!temporaryReferences?.includes(r.reference)"
              v-on:click="(e: Event) => e.stopPropagation()"
              v-on:input="() => updateReferences(r.reference)" />
            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:footer_button_on_click="() => updateDateFilter()"
          v-bind:number="filters.datePeriodeId !== undefined ? 1 : undefined">
          Date de commande
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="periode in PERIOD_IDS"
              v-bind:on_click="
                () => {
                  resetDateInputFilters();
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.datePeriodeId.name,
                    periode.id
                  );
                }
              "
              v-bind:key="periode.id">
              {{ periode.label }}
            </refonte-dropdown-item>
            <div class="p-2">
              <refonte-label label="Date" />
              <refonte-datepicker
                v-on:input="(value) => updateDate('date', value)"
                class="p-1"
                placeholder="Date exacte (JJ/MM/YYYY)"
                v-bind:value="date?.date" />
            </div>
            <refonte-separator />
            <div class="p-2">
              <refonte-label label="Période" />
              <refonte-datepicker
                class="p-1"
                placeholder="Début (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('debut', value)"
                v-bind:value="date?.debut" />
              <refonte-datepicker
                class="p-1"
                placeholder="Fin (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('fin', value)"
                v-bind:value="date?.fin" />
            </div>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="filters.commandeReglee !== undefined ? 1 : undefined">
          Commande réglée
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="commandeReglee in commandeRegleeList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.commandeReglee.name,
                    commandeReglee
                  )
              "
              v-bind:key="commandeReglee ? 'Oui' : 'Non'">
              {{ commandeReglee ? 'Oui' : 'Non' }}
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value: string) => updateVendeurValue(value)"
          v-bind:number="filters.vendeurId !== undefined ? 1 : undefined">
          Organisme de formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="vendeurList.length"
              v-for="vendeur in vendeurList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.vendeurId.name,
                    vendeur.id
                  )
              "
              v-bind:key="vendeur.id">
              {{ vendeur.nom }}
            </refonte-dropdown-item>
            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value: string) => updateParticipantValue(value)"
          v-bind:number="filters.contactId !== undefined ? 1 : undefined">
          Participant
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="participantList.length"
              v-for="participant in participantList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.contactId.name,
                    participant.id
                  )
              "
              v-bind:key="participant.id">
              <div>
                {{ participant.nomPrenom }}
              </div>
              <div>
                {{ participant.email }}
              </div>
            </refonte-dropdown-item>
            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value) => updateFormationValue(value)"
          v-bind:number="filters.formationId !== undefined ? 1 : undefined">
          Formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="formationList.length"
              v-for="formation in formationList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.formationId.name,
                    formation.id
                  )
              "
              v-bind:key="formation.id">
              {{ formation.titre }}
            </refonte-dropdown-item>

            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:footer_button_on_click="() => updateDateFormationFilter()"
          v-bind:number="
            filters.dateFormationPeriodeId !== undefined ? 1 : undefined
          ">
          Date de formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="periode in PERIOD_IDS"
              v-bind:on_click="
                () => {
                  resetDateFormationInputFilters();
                  updateFilter(
                    COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
                    periode.id
                  );
                }
              "
              v-bind:key="periode.id">
              {{ periode.label }}
            </refonte-dropdown-item>
            <div class="p-2">
              <refonte-label label="Date" />
              <refonte-datepicker
                v-on:input="(value) => updateDateFormation('date', value)"
                class="p-1"
                placeholder="Date exacte (JJ/MM/YYYY)"
                v-bind:value="dateFormation?.date" />
            </div>
            <refonte-separator />
            <div class="p-2">
              <refonte-label label="Période" />
              <refonte-datepicker
                class="p-1"
                placeholder="Début (JJ/MM/YYYY)"
                v-on:input="(value) => updateDateFormation('debut', value)"
                v-bind:value="dateFormation?.debut" />
              <refonte-datepicker
                class="p-1"
                placeholder="Fin (JJ/MM/YYYY)"
                v-on:input="(value) => updateDateFormation('fin', value)"
                v-bind:value="dateFormation?.fin" />
            </div>
          </template>
        </refonte-filters-item>
      </div>
      <refonte-filters-item
        v-bind:number="filters.nbResultats !== undefined ? 1 : undefined">
        Afficher
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="option in NB_RESULTAT_OPTIONS"
            v-bind:on_click="
              () =>
                updateFilter(
                  COMMANDES_FILTERS_CONFIGURATION.nbResultats.name,
                  option.id
                )
            "
            v-bind:key="option.id">
            {{ option.label }}
          </refonte-dropdown-item>
          <refonte-dropdown-item
            v-bind:on_click="
              () =>
                updateFilter(
                  COMMANDES_FILTERS_CONFIGURATION.nbResultats.name,
                  undefined
                )
            "
            v-bind:key="'tous'">
            Tous
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () => {
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
                updateFilter(filterApplied.id, undefined);
              updateTemporaryDates(filterApplied.id);
            }
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import {
    COMMANDES_FILTERS_CONFIGURATION,
    useCommandeFilterStore
  } from '@/stores/commandes';
  import {
    CommandeMetadata,
    CommandeMetadataFormation,
    CommandeMetadataParticipant,
    CommandeMetadataReference,
    CommandeMetadataVendeur
  } from '@/types/gestion/commandes-filters-types';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { API } from '@/http-common';
  import filters_utils from '@/mixin/utils/filters_utils';

  const referenceSearchValue = ref<string>('');

  const updateReferenceValue = (value: string) => {
    referenceSearchValue.value = value;
  };

  const vendeurSearchValue = ref<string>('');
  const updateVendeurValue = (value: string) => {
    vendeurSearchValue.value = value;
  };

  const participantSearchValue = ref<string>('');
  const updateParticipantValue = (value: string) => {
    participantSearchValue.value = value;
  };

  const formationSearchValue = ref<string>('');
  const updateFormationValue = (value: string) => {
    formationSearchValue.value = value;
  };

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDate = (name: string, value: undefined | Date | string) => {
    date.value[name] = value;
  };

  const updateDateFormation = (
    name: string,
    value: undefined | Date | string
  ) => {
    dateFormation.value[name] = value;
  };

  const getVendeurNom = (id: number | undefined) => {
    const item =
      vendeurFilterList.value &&
      vendeurFilterList.value.length &&
      vendeurFilterList.value.find((v) => v.id == id);
    return item
      ? filters_utils.methods.formatFilter('Organisme de formation', item.nom)
      : '';
  };
  const getProduitTitre = (id: number | undefined) => {
    const item =
      formationFilterList.value &&
      formationFilterList.value.length &&
      formationFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Formation', item.titre)
      : '';
  };
  const getParticipantNomPrenom = (id: number | undefined) => {
    const item =
      participantFilterList.value &&
      participantFilterList.value.length &&
      participantFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Participant', item.nomPrenom)
      : '';
  };

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);

  const NB_RESULTAT_OPTIONS = computed(() => [
    {
      id: 100,
      label: '100'
    }
  ]);

  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const commandeFilterStore = useCommandeFilterStore();
  const { filters } = storeToRefs(commandeFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[]
  ) => {
    commandeFilterStore.setFilter(name, value);
  };
  const date = ref<DateFilter>({
    date: filters.value.date,
    debut: filters.value.datePeriodeDebut,
    fin: filters.value.datePeriodeFin,
    periodeId: filters.value.datePeriodeId
  });
  const dateFormation = ref<DateFilter>({
    date: filters.value.dateFormation,
    debut: filters.value.dateFormationPeriodeDebut,
    fin: filters.value.dateFormationPeriodeFin,
    periodeId: filters.value.dateFormationPeriodeId
  });

  const resetFilters = () => {
    commandeFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    date.value = { ...dateFilterDefaut };
    dateFormation.value = { ...dateFilterDefaut };
    temporaryReferences.value = undefined;
  };
  const resetDateInputFilters = () => {
    updateFilter(COMMANDES_FILTERS_CONFIGURATION.date.name, undefined);
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      undefined
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      undefined
    );
    date.value = {
      ...date.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const resetDateFormationInputFilters = () => {
    updateFilter(COMMANDES_FILTERS_CONFIGURATION.dateFormation.name, undefined);
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      undefined
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      undefined
    );
    dateFormation.value = {
      ...dateFormation.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const updateDateFilter = () => {
    const dateValue = date.value;
    updateFilter(COMMANDES_FILTERS_CONFIGURATION.datePeriodeId.name, undefined);
    updateFilter(COMMANDES_FILTERS_CONFIGURATION.date.name, dateValue.date);
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      dateValue.debut
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      dateValue.fin
    );
  };
  const updateDateFormationFilter = () => {
    const dateValue = dateFormation.value;
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
      undefined
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormation.name,
      dateValue.date
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      dateValue.debut
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      dateValue.fin
    );
  };
  const updateTemporaryDates = (filterId: string) => {
    switch (filterId) {
      case COMMANDES_FILTERS_CONFIGURATION.date.name:
        updateDate('date', undefined);
        break;
      case COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name:
        updateDate('debut', undefined);
        break;
      case COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name:
        updateDate('fin', undefined);
        break;
      case COMMANDES_FILTERS_CONFIGURATION.dateFormation.name:
        updateDateFormation('date', undefined);
        break;
      case COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name:
        updateDateFormation('debut', undefined);
        break;
      case COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name:
        updateDateFormation('fin', undefined);
        break;
      default:
        break;
    }
  };

  const temporaryReferences = ref<string[] | undefined>(
    filters.value.references
  );

  const updateReferencesFilter = () => {
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.references.name,
      temporaryReferences.value && temporaryReferences.value.length > 0
        ? temporaryReferences.value
        : undefined
    );
  };

  const resetReferences = () => {
    temporaryReferences.value = filters.value.references;
  };

  const updateReferences = (value: string) => {
    if (temporaryReferences.value?.includes(value)) {
      temporaryReferences.value = temporaryReferences.value.filter(
        (r) => r !== value
      );
    } else {
      temporaryReferences.value = temporaryReferences.value
        ? [...temporaryReferences.value, value]
        : [value];
    }
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: COMMANDES_FILTERS_CONFIGURATION.references.name,
      value: filters.value.references
        ? filters_utils.methods.formatFilter(
            `Référence${filters.value.references.length > 1 ? 's' : ''}`,
            filters.value.references.join(', ')
          )
        : undefined
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.date.name,
      value: filters_utils.methods.formatFilter(
        'Date de commande',
        formatDate(filters.value.date)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de commande',
        getPeriodFilterApply(filters.value.datePeriodeId)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de commande',
        `Après le
          ${formatDate(filters.value.datePeriodeDebut)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de commande',
        `Avant le
          ${formatDate(filters.value.datePeriodeFin)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.commandeReglee.name,
      value: filters_utils.methods.hasValue(filters.value.commandeReglee)
        ? filters_utils.methods.formatFilter(
            'Commande réglée',
            filters.value.commandeReglee ? 'Oui' : 'Non'
          )
        : undefined
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.vendeurId.name,
      value: getVendeurNom(filters.value.vendeurId)
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.contactId.name,
      value: getParticipantNomPrenom(filters.value.contactId)
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.formationId.name,
      value: getProduitTitre(filters.value.formationId)
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.dateFormation.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        formatDate(filters.value.dateFormation)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        getPeriodFilterApply(filters.value.dateFormationPeriodeId)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Après le
          ${formatDate(filters.value.dateFormationPeriodeDebut)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Avant le
          ${formatDate(filters.value.dateFormationPeriodeFin)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.nbResultats.name,
      value: getLabel(
        NB_RESULTAT_OPTIONS.value,
        filters.value.nbResultats,
        'Résultats affichés'
      )
    }
  ]);

  const getLabel = (
    list: { id: string | number; label: string }[],
    id: number | string | undefined,
    name: string
  ) => {
    const item = list && list.length && list.find((i) => i.id == id);
    return item ? filters_utils.methods.formatFilter(name, item.label) : '';
  };

  const referenceFilterList = ref<CommandeMetadataReference[]>([]);

  const referencesList = computed(() =>
    referenceFilterList.value && referenceFilterList.value.length
      ? referenceFilterList.value.filter(
          (r) =>
            referenceSearchValue.value.length < 3 ||
            r.reference
              .toLowerCase()
              .includes(referenceSearchValue.value.toLowerCase())
        )
      : []
  );

  const vendeurFilterList = ref<CommandeMetadataVendeur[]>([]);

  const vendeurList = computed(() =>
    vendeurFilterList.value && vendeurFilterList.value.length
      ? vendeurFilterList.value.filter(
          (f) =>
            vendeurSearchValue.value.length < 3 ||
            f.nom.toLowerCase().includes(vendeurSearchValue.value.toLowerCase())
        )
      : []
  );

  const commandeRegleeList = computed(() => [true, false]);

  const participantFilterList = ref<CommandeMetadataParticipant[]>([]);

  const participantList = computed(() =>
    participantFilterList.value && participantFilterList.value.length
      ? participantFilterList.value.filter(
          (p) =>
            participantSearchValue.value.length < 3 ||
            p.nomPrenom
              .toLowerCase()
              .includes(participantSearchValue.value.toLowerCase())
        )
      : []
  );

  const formationFilterList = ref<CommandeMetadataFormation[]>([]);

  const formationList = computed(() =>
    formationFilterList.value && formationFilterList.value.length
      ? formationFilterList.value.filter(
          (f) =>
            formationSearchValue.value.length < 3 ||
            f.titre
              .toLowerCase()
              .includes(formationSearchValue.value.toLowerCase())
        )
      : []
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const metadataLoaded = ref<boolean>(false);

  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/commandes/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    );

    if (response && response.data) {
      const data: CommandeMetadata = response.data;
      referenceFilterList.value = data.references ?? [];
      vendeurFilterList.value = data.vendeurs ?? [];
      participantFilterList.value = data.participants ?? [];
      formationFilterList.value = data.formations ?? [];
    }
    metadataLoaded.value = true;
  };

  watch(activeClientIds, () => {
    resetFilters();
    fetchMetadata();
  });

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped>
  .filters-group {
    @apply flex flex-grow flex-wrap items-center;
    gap: 8px;
  }
</style>
