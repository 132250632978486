import { API } from '@/http-common.js';
import { IdLabel } from '@/types/gestion/entreprise';
import { Filters } from '@/types/gestion/factures-filters-types';
import { Facture, FactureResponse } from '@/types/gestion/factures-types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    typeIds: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    commandes: undefined,
    references: undefined,
    vendeurId: undefined,
    participantId: undefined,
    formationId: undefined,
    dateFormation: undefined,
    dateFormationPeriodeId: undefined,
    dateFormationPeriodeDebut: undefined,
    dateFormationPeriodeFin: undefined,
    nbResultats: 100
};

const filterInitialState: Filters = {
    clientIds: [],
    typeIds: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    commandes: undefined,
    references: undefined,
    vendeurId: undefined,
    participantId: undefined,
    formationId: undefined,
    dateFormation: undefined,
    dateFormationPeriodeId: undefined,
    dateFormationPeriodeDebut: undefined,
    dateFormationPeriodeFin: undefined,
    nbResultats: 100
};

export const FACTURES_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    typeIds: {
        name: 'typeIds',
        isArray: true
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    references: {
        name: 'references',
        isArray: true
    },
    commandes: {
        name: 'commandes',
        isArray: true
    },
    vendeurId: {
        name: 'vendeurId',
        isArray: false
    },
    participantId: {
        name: 'participantId',
        isArray: false
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    dateFormation: {
        name: 'dateFormation',
        isArray: false
    },
    dateFormationPeriodeId: {
        name: 'dateFormationPeriodeId',
        isArray: false
    },
    dateFormationPeriodeDebut: {
        name: 'dateFormationPeriodeDebut',
        isArray: false
    },
    dateFormationPeriodeFin: {
        name: 'dateFormationPeriodeFin',
        isArray: false
    },
    nbResultats: {
        name: 'nbResultats',
        isArray: false
    }
};

export const useFactureFilterStore = defineStore('factures-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = FACTURES_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
            if (
                [
                    FACTURES_FILTERS_CONFIGURATION.date.name,
                    FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
                    FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name,
                    FACTURES_FILTERS_CONFIGURATION.dateFormation.name,
                    FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut
                        .name,
                    FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name
                ].includes(key)
            ) {
                newPayload[key] = new Date(newPayload[key]);
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useFactureStore = defineStore('factures', () => {
    const allCheckboxSelected = ref(false);
    const factures = ref<Facture[]>([]);

    const fetchFactures = async (query: Filters) => {
        const response = await API.get(`client/v2/factures`, {
            params: query
        });
        if (response?.data) {
            factures.value = response?.data.map((f: FactureResponse) =>
                mapFacture(f)
            );
        }
    };

    const mapFacture = (f: FactureResponse): Facture => {
        return {
            ...f,
            clientDenomination: f.client.denomination,
            clientId: f.client.id,
            clientSiret: f.client.siret ?? undefined,
            referenceEtType: `${f.type ? `${f.type} : ` : ''}${f.reference}`,
            organisateur: f.vendeur.nom,
            montantHt: buildMontant(f.montantHt),
            montantTtc: buildMontant(f.montantTtc),
            montantRegle:
                f.montantRegle !== undefined && f.montantRegle !== null
                    ? buildMontant(f.montantRegle)
                    : undefined,
            statutLabel: f.statut ? [f.statut.label] : [],
            statutVariant: buildStatutVariant(f.statut),
            ...(f.factureNom && {
                factureNom: 'Télécharger'
            })
        };
    };

    const buildMontant = (montant: number): string => {
        const formatter = new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        });
        return formatter.format(montant);
    };

    const buildStatutVariant = (statut?: IdLabel) => {
        const variant =
            statut && statut.label === 'Acquittée'
                ? 'success'
                : statut && statut.label === 'Non acquittée'
                  ? 'warning'
                  : 'deactivated';
        return [variant];
    };

    const selectFactureItem = (id: string, value: boolean) => {
        const factureIndex = factures.value.findIndex((f) => f.id === id);
        if (factureIndex != -1) {
            const newFactures = factures.value.slice();
            newFactures[factureIndex] = {
                ...newFactures[factureIndex],
                selected: value
            };
            factures.value = newFactures;
        }
    };

    const selectAllFactureItems = (value: boolean) => {
        allCheckboxSelected.value = true;
        factures.value = factures.value
            .slice()
            .map((f) => ({ ...f, selected: value }));
    };

    const hasFacturesSelected = computed(() =>
        factures.value.some((f) => f.selected)
    );

    const getFacturesSelected = computed(() => [
        ...factures.value.filter((f) => f.selected)
    ]);

    return {
        factures,
        fetchFactures,
        selectFactureItem,
        selectAllFactureItems,
        hasFacturesSelected,
        getFacturesSelected
    };
});
