<template>
  <refonte-filters
    v-if="metadataLoaded"
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.contactId)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateContactValue(value)"
        v-bind:number="filters.contactId !== undefined ? 1 : undefined">
        Contact
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="contactList.length"
            v-for="contact in contactList"
            v-bind:on_click="
              () =>
                updateFilter(
                  ANNUAIRE_FILTERS_CONFIGURATION.contactId.name,
                  contact.id
                )
            "
            v-bind:key="contact.id">
            <div>
              {{ contact.nomPrenom }}
            </div>
            <div>
              {{ contact.email }}
            </div>
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import filters_utils from '@/mixin/utils/filters_utils';
  import {
    ANNUAIRE_FILTERS_CONFIGURATION,
    useAnnuaireFilterStore
  } from '@/stores/annuaire';
  import {
    AnnuaireMetadata,
    AnnuaireMetadataContact
  } from '@/types/gestion/annuaire-filters-types';
  import { API } from '@/http-common';
  import { useEntrepriseStore } from '@/stores/entreprise';

  const contactSearchValue = ref<string>('');
  const updateContactValue = (value: string) => {
    contactSearchValue.value = value;
  };

  const getContactNomPrenom = (id: number | undefined) => {
    const item =
      contactFilterList.value &&
      contactFilterList.value.length &&
      contactFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Contact', item.nomPrenom)
      : '';
  };

  const annuaireFilterStore = useAnnuaireFilterStore();
  const { filters } = storeToRefs(annuaireFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean
  ) => {
    annuaireFilterStore.setFilter(name, value);
  };

  const resetFilters = () => {
    annuaireFilterStore.resetFilters();
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: ANNUAIRE_FILTERS_CONFIGURATION.contactId.name,
      value: getContactNomPrenom(filters.value.contactId)
    }
  ]);

  const contactFilterList = ref<AnnuaireMetadataContact[]>([]);

  const contactList = computed(() =>
    contactFilterList.value && contactFilterList.value.length
      ? contactFilterList.value.filter(
          (c) =>
            contactSearchValue.value.length < 3 ||
            c.nomPrenom
              .toLowerCase()
              .includes(contactSearchValue.value.toLowerCase())
        )
      : []
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeAdminClientIds, activeEntrepriseIds } =
    storeToRefs(entrepriseStore);

  const metadataLoaded = ref<boolean>(false);

  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/annuaires/metadata${activeAdminClientIds.value.length ? '?clientIds=' + activeAdminClientIds.value : ''}`
    );

    if (response && response.data) {
      const data: AnnuaireMetadata = response.data;
      contactFilterList.value = data.contacts ?? [];
    }
    metadataLoaded.value = true;
  };

  watch(activeEntrepriseIds, () => {
    resetFilters();
    fetchMetadata();
  });

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
